import React from 'react'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Link } from 'react-router-dom'
import { Logo } from '@/components/ui/general/Logo'

import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const Footer = () => {
	const { t } = useTranslation()

	const footerLinkList = [
		{
			id: 0,
			name: t('footer.links.privacy'),
			link: '/privacy',
		},
		{
			id: 1,
			name: t('footer.links.terms'),
			link: '/terms',
		},
	]

	return (
		<div className={styles.footerWrapper}>
			<div className='container-big phone:p-0'>
				<div className={styles.footer}>
					<div className={styles.footerInfo}>
						<Logo direction={'footer'} />

						<RootDesc>
							<span className='text-[var(--white)]'>
								{t('footer.copyright')}
							</span>
						</RootDesc>
					</div>

					<div className={styles.footerContent}>
						<h2>
							<Trans i18nKey='footer.description'>
								{t('footer.description')}
							</Trans>
						</h2>

						<div className={styles.contentBottom}>
							<ul>
								{footerLinkList.map(item => (
									<li key={item?.id}>
										<RootDesc>
											<Link to={item?.link}>{item?.name}</Link>
										</RootDesc>
									</li>
								))}
							</ul>

							<RootDesc>
								<span className='text-[var(--white)]'>
									{t('footer.contact')}{' '}
									<a target='_blank' href='mailto:info@vivanti.eu'>
										info@vivanti.eu
									</a>
								</span>
							</RootDesc>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
