import mapImage from '@/assets/images/general/world.png'
import RootSelect from '@/components/ui/inputs/RootSelect'
import { H3 } from '@/components/ui/titles/H3'

import styles from './World.module.scss'
import { useTranslation } from 'react-i18next'

export const World = () => {
	const { t } = useTranslation()

	const selectOptions = [
		{ id: 0, name: 'Europe' },
		{ id: 1, name: 'Africa' },
		{ id: 2, name: 'Central Asia' },
		{ id: 3, name: 'Middle East' },
		{ id: 4, name: 'North America' },
		{ id: 5, name: 'South America' },
	]

	const handleSelect = option => {
		console.log(option.name)
	}

	return (
		<div className={styles.world}>
			<div className={styles.worldHeader}>
				<H3>{t('page.dashboard.world_title')}</H3>

				<RootSelect
					options={selectOptions}
					onSelect={handleSelect}
					placeholder={selectOptions[0]}
				/>
			</div>

			<div className={styles.worldMap}>
				<img src={mapImage} alt='world' />
			</div>
		</div>
	)
}
