export const capitalize = str => {
	if (!str) return

	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEqual = (editObj, currentObj) => {
	if (!editObj || !currentObj) return

	return Object.keys(editObj).every(key => editObj[key] === currentObj[key])
}

export const isValidEmail = email => {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

	return emailPattern.test(email)
}
