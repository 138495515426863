import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getScenarioFeedback,
	setIsSessionActive,
	getProductFeedback,
} from '@/redux/slices/avatarSlices/avatarSlice'
import { useScoreValue } from '@/hooks/ScoreValue'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { Spinner } from '@/components//ui/general/Spinner'

import low from '@/assets/images/popups/low-rating.svg'
import medium from '@/assets/images/popups/medium-rating.svg'
import high from '@/assets/images/popups/high-rating.svg'
import logo from '@/assets/images/general/logo.svg'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const FeedbackCard = props => {
	const [isOpen, setIsOpen] = useState(false)
	const item = props.feedback

	return (
		<div className={styles.feedbackCard} key={item?.id}>
			<div onClick={() => setIsOpen(!isOpen)} className={styles.cardHead}>
				<h4>{item?.title}</h4>

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>
			</div>

			{isOpen && (
				<div className={styles.cardBody}>
					{item?.products && (
						<ul>
							{item?.products.map(product => (
								<li key={product?.id}>
									<div className='flex items-center'>
										<RootDesc>
											<b>{product?.name}</b>
										</RootDesc>
									</div>

									<img src={product?.rating} alt='rating' />
								</li>
							))}
						</ul>
					)}

					<div className={item?.products ? 'max-w-[488rem]' : 'max-w-[100%]'}>
						<RootDesc>
							<span>{item?.text}</span>
						</RootDesc>
					</div>
				</div>
			)}
		</div>
	)
}

export const AiFeedbackPopup = props => {
	const dispatch = useDispatch()
	const currentScore = useScoreValue()
	const { t } = useTranslation()

	const { again, quit } = props
	const {
		feedback,
		scenario_session_id,
		product_session_id,
		serverStatus,
		score,
	} = useSelector(state => state.avatar)

	const feedbackList = [
		{
			id: 0,
			title: 'Product mention on the call:',
			text: 'You dedicated time only to two products (Product 1 and Product 2), but Product 3 was not mentioned during the call. Please try to allocate time on the call so that all products are covered. Additionally, make sure to follow the prescribed product order. You started with Product 2, whereas the cycle book indicated you should have started with Product 1.',
			products: [
				{
					id: 0,
					name: 'Product 1',
					rating: high,
				},
				{
					id: 1,
					name: 'Product 2',
					rating: high,
				},
				{
					id: 2,
					name: 'Product 3',
					rating: low,
				},
			],
		},
		{
			id: 1,
			title: 'Key Messages',
			text: 'During the visit, you covered all the key messages for Product 1—great job. For Product 2, only Key Message 1 and Key Message 2 were mentioned, while Key Message 3 was missed. Product 3 was not presented, and its key messages were not conveyed. It is recommended to allocate time to all products and ensure all key messages are addressed.',
			products: [
				{
					id: 0,
					name: 'Product 1',
					rating: high,
				},
				{
					id: 1,
					name: 'Product 2',
					rating: medium,
				},
				{
					id: 2,
					name: 'Product 3',
					rating: low,
				},
			],
		},
		{
			id: 2,
			title: 'Tips for improving dialogue',
			text: 'Your dialogue structure is quite good, but there are a few areas for improvement. It’s better to address the doctor by name rather than just saying "doctor," as it personalizes the conversation and helps build rapport. Additionally, avoid asking common, generic questions that could irritate the doctor, such as "Have I convinced you?" or "Your patients will thank you for this." Instead, focus on more meaningful and specific questions to keep the conversation engaging and professional.',
		},
	]

	useEffect(() => {
		if (product_session_id) {
			dispatch(getProductFeedback(product_session_id))
		} else {
			dispatch(getScenarioFeedback(scenario_session_id))
		}

		dispatch(setIsSessionActive(false))
	}, [])

	return (
		<div className={styles.feedbackPopup}>
			<div className={styles.logo}>
				<img src={logo} alt='ava-logo' />
			</div>

			<div className={styles.feedbackTitle}>
				<H3>{t('popup.feedback.title')}</H3>
			</div>

			<div className={styles.feedbackContent}>
				{serverStatus === 'loading' ? (
					<Spinner width={80} height={80} />
				) : (
					<>
						{/* <RootDesc>
							<span>{currentScore(score).description}</span>
						</RootDesc> */}

						<RootDesc>
							<span>{feedback}</span>
						</RootDesc>

						{/* дополнение в попап
						{feedbackList &&
							feedbackList.map(item => <FeedbackCard feedback={item} />)} */}

						<div className={styles.feedbackBtns}>
							<RootButton
								txt={t('button.again')}
								type={'button'}
								onClick={again}
							/>

							<RootButton
								txt={t('button.exit_simulation')}
								type={'button'}
								onClick={quit}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
