import { useTranslation } from 'react-i18next'

export const useCurrentRole = () => {
	const { t } = useTranslation()

	const currentRole = role => {
		switch (role) {
			case 'Admin':
				return t('role.admin')
			case 'Manager':
				return t('role.manager')
			case 'Representative':
				return t('role.rep')
			default:
				return ''
		}
	}

	return currentRole
}
