import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'

export const useChatTime = duration => {
	if (duration <= 0) {
		console.log(duration)
		console.warn('Duration must be a positive number')

		return [null, () => {}, () => {}]
	}

	const expiryTimestamp = new Date(Date.now() + duration)

	const { totalSeconds, start, restart } = useTimer({
		expiryTimestamp,
		autoStart: false,
		onExpire: () => console.warn('onExpire called'),
	})

	const [chatTime, setChatTimeState] = useState(null)
	const { messages } = useSelector(state => state.avatar)

	useEffect(() => {
		if (messages.length > 0) {
			start()

			const remainingMilliseconds = totalSeconds * 1000

			setChatTimeState(remainingMilliseconds)
		}
	}, [messages, totalSeconds])

	return [chatTime, setChatTimeState, restart]
}
