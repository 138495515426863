import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SessionsService from '@/services/SessionsService'

export const viewScenarioSession = createAsyncThunk(
	'scenario-session/view',
	async ({ id }) => {
		try {
			const response = await SessionsService.viewScenarioSession({ id })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

export const viewProductSession = createAsyncThunk(
	'product-session/view',
	async ({ id }) => {
		try {
			const response = await SessionsService.viewProductSession({ id })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	session: null,
	error: '',
	serverStatus: '',
}

const viewSessionSlice = createSlice({
	name: 'view-session',
	initialState,
	reducers: {
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearViewSession() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(viewScenarioSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewScenarioSession.fulfilled, (state, action) => {
				state.session = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewScenarioSession.rejected, (state, action) => {
				state.error = 'Don`t view session!'
				state.serverStatus = 'error'
			})

			.addCase(viewProductSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(viewProductSession.fulfilled, (state, action) => {
				state.session = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(viewProductSession.rejected, (state, action) => {
				state.error = 'Don`t view session!'
				state.serverStatus = 'error'
			})
	},
})

export const { clearViewSession, setError, setServerStatus } =
	viewSessionSlice.actions
export default viewSessionSlice.reducer
