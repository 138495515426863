import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	viewProduct,
	clearViewProduct,
} from '@/redux/slices/productSlices/viewProductSlice'
import { Trans, useTranslation } from 'react-i18next'

import { capitalize } from '@/helpers/helpers'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { useLevelOrder } from '@/hooks/LevelOrder'

import styles from './styles.module.scss'

const ProductField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem] phone:w-[100%]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

const ProductLevelDocuments = ({ level, files }) => {
	const { levels } = useLevelOrder()
	const { t } = useTranslation()

	const currentLevel = levels.find(l => l.value === level)

	return (
		<div className={styles.productLevel}>
			<RootDesc>
				<b className='block w-[200rem]'>
					{currentLevel ? capitalize(currentLevel.name) : capitalize(level)}{' '}
					{t('page.product.field.level')}
				</b>
			</RootDesc>

			<div className={styles.productDocuments}>
				{files.map(file => (
					<div key={file.id}>
						<SmallDesc>
							<span className={styles.plateDocument}>
								{file.metadata?.filename}
							</span>
						</SmallDesc>
					</div>
				))}
			</div>
		</div>
	)
}

export const ProductViewLayout = () => {
	const { levels } = useLevelOrder()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { id } = useParams()

	const { isMobile } = useSelector(state => state.settings)
	const { demo } = useSelector(state => state.candidate)
	const { product, serverStatus } = useSelector(state => state.viewProduct)

	const [groupedFiles, setGroupedFiles] = useState([])

	const productRef = useRef()

	const handleClickEdit = id => {
		navigate(`/admin-panel/products/edit/${id}`, { state: { id } })
	}

	useEffect(() => {
		dispatch(viewProduct({ id }))

		return () => {
			dispatch(clearViewProduct())
		}
	}, [dispatch, id])

	useEffect(() => {
		if (product?.product_version?.files) {
			const filesByLevel = levels.map(level => ({
				level: level.value,
				file: product.product_version.files.filter(
					file => file.level === level.value
				),
			}))

			setGroupedFiles(filesByLevel)
		}
	}, [product])

	useEffect(() => {
		if (productRef.current && isMobile) {
			productRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={productRef} className={styles.productWrapper}>
			<BorderArticle>
				<div className={styles.product}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.productContent}>
							<div className={styles.productContentHeadWrapper}>
								<div className={styles.productContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<h2>{product?.product?.name}</h2>

										{!demo && (
											<ControlButton
												color='var(--blue)'
												txt={t('button.edit')}
												onClick={() => handleClickEdit(product?.product?.id)}
											/>
										)}
									</div>

									<RootDesc>
										<b>{t('page.scenario.field.preview')}</b>
									</RootDesc>

									<PreviewCard
										name={product?.product?.name}
										mask={product?.product?.mask}
										image={product?.product?.cover}
									/>
								</div>
							</div>

							<ul className={styles.productContentBody}>
								<ProductField
									label={t('page.product.field.country')}
									value={product?.product?.country}
								/>

								<ProductField
									label={t('page.product.field.product_line')}
									value={product?.product?.product_line}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											<Trans i18nKey='page.product.field.documents'>
												{t('page.product.field.documents')}
											</Trans>
										</b>
									</RootDesc>

									<div className={styles.productLevelList}>
										{groupedFiles.map(item => (
											<ProductLevelDocuments
												key={item?.level}
												level={item?.level}
												files={item?.file}
											/>
										))}
									</div>
								</li>

								<ProductField
									label={
										<Trans i18nKey='page.product.field.questions'>
											{t('page.product.field.questions')}
										</Trans>
									}
									value={product?.product_version?.questions}
								/>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
