import { configureStore } from '@reduxjs/toolkit'

import settings from './slices/settingsSlice'

import candidate from './slices/candidateSlice'

import help from './slices/requestSlices/helpSlice'
import call from './slices/requestSlices/callSlice'
import message from './slices/requestSlices/presentationSlice'

import change from './slices/changeRequestSlice'
import simulations from './slices/simulationsSlice'

import products from './slices/productSlices/productsSlice'
import productOptions from './slices/productSlices/productOptionsSlice'
import viewProduct from './slices/productSlices/viewProductSlice'
import editProduct from './slices/productSlices/editProductSlice'
import createProduct from './slices/productSlices/createProductSlice'

import scenarios from './slices/scenarioSlices/scenariosSlice'
import scenarioOptions from './slices/scenarioSlices/scenarioOptionsSlice'
import viewScenario from './slices/scenarioSlices/viewScenarioSlice'
import editScenario from './slices/scenarioSlices/editScenarioSlice'
import createScenario from './slices/scenarioSlices/createScenarioSlice'

import users from './slices/userSlices/usersSlice'
import userOptions from './slices/userSlices/userOptionsSlice'
import viewUser from './slices/userSlices/viewUserSlice'
import editUser from './slices/userSlices/editUserSlice'
import createUser from './slices/userSlices/createUserSlice'

import sessions from './slices/sessionSlices/sessionsSlice'
import viewSession from './slices/sessionSlices/viewSessionSlice'

import editVideo from './slices/videoSlices/editVideoSlice'
import videos from './slices/videoSlices/videosSlice'
import viewVideo from './slices/videoSlices/viewVideoSlice'
import createVideo from './slices/videoSlices/createVideoSlice'

import avatar from './slices/avatarSlices/avatarSlice'
import upload from './slices/uploadSlices/uploadFileSlice'

export const store = configureStore({
	reducer: {
		settings,
		candidate,

		simulations,

		help,
		call,
		change,
		message,

		editProduct,
		viewProduct,
		productOptions,
		createProduct,
		products,

		editScenario,
		viewScenario,
		scenarioOptions,
		createScenario,
		scenarios,

		users,
		viewUser,
		userOptions,
		editUser,
		createUser,

		sessions,
		viewSession,

		videos,
		viewVideo,
		editVideo,
		createVideo,

		avatar,
		upload,
	},
})
