import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { Trans, useTranslation } from 'react-i18next'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { DropZone } from '@/components/ui/general/DropZone'

import styles from './styles.module.scss'

const UploadSection = ({ title, description, accept, onUpload, maxFiles }) => (
	<div className={styles.upload}>
		<H3>{title}</H3>

		<div className={styles.uploadDesc}>
			<RootDesc>
				<span>{description}</span>
			</RootDesc>
		</div>

		<DropZone
			maxFiles={maxFiles}
			accept={accept}
			onUpload={onUpload}
			size={50000000}
			type={'.txt'}
		/>
	</div>
)

export const UploadFilePopup = ({ level, fileList, setFileList }) => {
	const { closePopup } = usePopup()
	const { t } = useTranslation()

	const handleUpload = files => {
		let updatedFileList = []

		if (!files || files.length === 0) {
			console.log('Files is undefined!')

			return
		}

		if (level) {
			updatedFileList = [...fileList, { level, file: files }]
		} else {
			updatedFileList = [...fileList, { file: files }]
		}

		setFileList(updatedFileList)
		closePopup()
	}

	const uploadConfig = {
		accept: {
			'text/plain': ['.txt'],
		},
		onUpload: handleUpload,
	}

	if (level) {
		return (
			<UploadSection
				title={
					<Trans level={level} i18nKey='popup.upload_level_file.title'>
						{t('popup.upload_level_file.title')}
						<span>{{ level }}</span>
					</Trans>
				}
				description={
					<Trans i18nKey='popup.upload_level_file.subtitle'>
						{t('popup.upload_level_file.subtitle')}
					</Trans>
				}
				{...uploadConfig}
				maxFiles={1}
			/>
		)
	} else {
		return (
			<UploadSection
				title={
					<Trans i18nKey='popup.upload_doc_file.title'>
						{t('popup.upload_doc_file.title')}
					</Trans>
				}
				description={
					<Trans i18nKey='popup.upload_doc_file.subtitle'>
						{t('popup.upload_doc_file.subtitle')}
					</Trans>
				}
				{...uploadConfig}
				maxFiles={0}
			/>
		)
	}
}
