import React from 'react'
import CountUp from 'react-countup'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const StatisticCardsLayout = ({ list }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.overviewList}>
			{list &&
				list.map(item => (
					<div key={item?.id}>
						<BorderArticle>
							<div className={styles.overviewItem}>
								<img src={item?.icon} alt='icon' />

								<div className={styles.overviewItemContent}>
									<RootDesc>
										<b className='font-[600] text-[var(--grey)]'>
											{item?.title}
										</b>
									</RootDesc>

									<div className={styles.overviewItemBottom}>
										<strong>
											<CountUp end={item?.total} duration={2} />
										</strong>

										<RootDesc>
											<b
												style={
													item?.totalChange > 0
														? { color: 'var(--green)' }
														: { color: 'var(--red)' }
												}
												className='font-[23rem]'
											>
												<CountUp end={item?.totalChange} duration={4} />
											</b>
											<br />
											<span className='text-[var(--grey)]'>
												<Trans i18nKey='overview_card.subtitle'>
													{t('overview_card.subtitle')}
												</Trans>
											</span>
										</RootDesc>
									</div>
								</div>
							</div>
						</BorderArticle>
					</div>
				))}
		</div>
	)
}
