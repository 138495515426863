import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewUser,
	viewUser,
} from '@/redux/slices/userSlices/viewUserSlice'
import { setLanguage } from '@/redux/slices/settingsSlice'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'

import { usePopup } from '../InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { ChangeRequestPopup } from '@/popups/requestPopups/ChangeRequestPopup'
import { UploadFileProgressPopup } from '@/popups/uploadPopups/UploadFileProgressPopup'
import { ChangePassPopup } from '@/popups/requestPopups/ChangePassPopup'
import { Spinner } from '@/components/ui/general/Spinner'
import UserImage from '@/components/ui/general/UserImage'
import EditSelect from '@/components/ui/inputs/EditSelect'

import photoImage from '@/assets/images/cards/photo.svg'
import styles from './styles.module.scss'

import 'moment/locale/ru'

const UserInfoField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block min-w-[200rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const ProfileLayout = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const id = useParams().id
	const { openPopup } = usePopup()
	const { user, serverStatus } = useSelector(state => state.viewUser)

	const handleOpenPopup = () => {
		openPopup(<ChangeRequestPopup />)
	}

	const handleClickImage = () => {
		openPopup(<UploadFileProgressPopup type={'image'} />)
	}

	const handleClickChangePass = () => {
		openPopup(<ChangePassPopup />)
	}

	const languages = [
		{ id: 0, name: 'English', value: 'en' },
		{ id: 1, name: 'Russian', value: 'ru' },
	]

	const handleSelectLanguage = option => {
		dispatch(setLanguage(option.value))
		Cookies.set('language', option.value)
		i18n.changeLanguage(option.value)
		moment.locale(option.value)
	}

	const definitionLanguage = () => {
		if (user?.language) {
			return user?.language
		} else {
			const languageValue = Cookies.get('language')
			const currentLanguage = languages.find(
				lang => lang.value === languageValue
			)

			return currentLanguage.name
		}
	}

	useEffect(() => {
		dispatch(viewUser({ id }))

		return () => {
			dispatch(clearViewUser())
		}
	}, [dispatch, id])

	return (
		<div className={styles.profileWrapper}>
			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : (
				<div className={styles.profile}>
					<div className={styles.profileInfo}>
						<UserImage
							onClick={user?.role !== 'Admin' ? handleClickImage : undefined}
							photoImage={user?.role !== 'Admin' ? photoImage : undefined}
							photoFile={user?.metadata}
							user={user}
						/>
					</div>

					<div className={styles.content}>
						<ul>
							<UserInfoField
								label={t('page.profile.field.last_name')}
								value={user?.lastname}
							/>

							<UserInfoField label='Email' value={user?.email} />

							<li>
								<RootDesc>
									<b className='block min-w-[200rem]'>
										{t('page.profile.field.password')}
									</b>
								</RootDesc>

								<RootDesc>
									<span>
										*********
										{user?.role !== 'Admin' && (
											<i className={styles.profileEditPassword}>
												<ControlButton
													color='var(--blue)'
													txt={t('button.edit')}
													onClick={handleClickChangePass}
												/>
											</i>
										)}
									</span>
								</RootDesc>
							</li>

							<UserInfoField
								label={t('page.profile.field.role')}
								value={user?.role}
							/>

							{user?.role !== 'Admin' && (
								<>
									<UserInfoField
										label={t('page.profile.field.product_line')}
										value={user?.product_line}
									/>

									<UserInfoField
										label={t('page.profile.field.country')}
										value={user?.country}
									/>
								</>
							)}

							<li>
								<RootDesc>
									<b className='block min-w-[200rem]'>
										{t('page.profile.field.language')}
									</b>
								</RootDesc>

								<EditSelect
									activeOption={definitionLanguage()}
									options={languages}
									onSelect={handleSelectLanguage}
								/>
							</li>
						</ul>

						<ControlButton
							color='var(--blue)'
							txt={
								user?.role !== 'Admin'
									? t('button.change_req')
									: t('button.edit')
							}
							onClick={
								user?.role !== 'Admin'
									? handleOpenPopup
									: () => navigate(`/admin-panel/users/edit/${id}`)
							}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
