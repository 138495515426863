import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewVideo,
	viewVideo,
} from '@/redux/slices/videoSlices/viewVideoSlice'

import { BorderArticle } from '@/components/ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components/ui/general/Spinner'

import doctorObjections from '@/assets/example-videos/doctor-objections.jpg'
import sideEffects from '@/assets/example-videos/side-effects.png'
import painxicam from '@/assets/example-videos/painxicam.png'
import rolexin from '@/assets/example-videos/rolexin.png'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const VideoField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block w-[200rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const VideoViewLayout = () => {
	const { isMobile } = useSelector(state => state.settings)
	const { demo } = useSelector(state => state.candidate)
	const { video, serverStatus } = useSelector(state => state.viewVideo)
	const { t } = useTranslation()
	const id = useParams().id
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()

	const videoRef = useRef()

	const data = useMemo(
		() => [
			{
				id: 0,
				name: 'Handling Doctor Objections',
				product: 'Rolexin',
				line: 'Objections',
				country: 'Germany',
				image: doctorObjections,
				mask: 'pinkGr',
				description:
					'This training video provides strategies for addressing common objections raised by doctors. Learn key techniques to respond confidently and effectively, ensuring productive conversations and stronger professional relationships.',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 1,
				name: 'Rolexin: Marketing Strategy C1',
				product: 'Rolexin',
				line: 'Neurology',
				country: 'Germany',
				mask: 'peachGr',
				image: rolexin,
				description:
					'This training session provides an overview of the key topics for calls in Cycle 1, including the objectives and goals of these calls, as well as the essential messages to communicate.',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 2,
				name: 'Painxicam: Marketing Strategy C1',
				product: 'Painxicam',
				line: 'Neurology',
				mask: 'violetGr',
				country: 'Germany',
				image: painxicam,
				description:
					'This training session provides an overview of the key topics for calls in Cycle 1, including the objectives and goals of these calls, as well as the essential messages to communicate.',
				created_at: '2024-10-21',
				inactive: false,
			},
			{
				id: 3,
				name: 'Rolexin: Side Effects and Drug Compatibility',
				product: 'Rolexin',
				line: 'Neurology',
				mask: 'violetGr',
				country: 'Germany',
				image: sideEffects,
				description:
					'This training video explores the side effects associated with Rolexin and its compatibility with other medications. Participants will gain a comprehensive understanding of the potential adverse reactions, how to recognize them, and the importance of drug interactions.',
				created_at: '2024-10-21',
				inactive: false,
			},
		],
		[]
	)

	const handleClickEdit = async id => {
		navigate(`/admin-panel/videos/edit/${id}`, { state: { id } })
	}

	useEffect(() => {
		dispatch(viewVideo({ id }))

		return () => {
			dispatch(clearViewVideo())
		}
	}, [dispatch])

	useEffect(() => {
		if (videoRef.current && isMobile) {
			videoRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={videoRef} className={styles.videoWrapper}>
			<BorderArticle>
				<div className={styles.video}>
					<div className={styles.videoHead}>
						<BackButton />
					</div>

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.videoContent}>
							<div className={styles.videoContentHead}>
								<div className='flex items-center gap-[16rem]'>
									<h2>{data[id]?.name}</h2>

									{!demo && (
										<ControlButton
											color='var(--blue)'
											txt={t('button.edit')}
											onClick={() => handleClickEdit(data[id]?.id)}
										/>
									)}
								</div>

								<RootDesc>
									<b>{t('page.scenario.field.preview')}</b>
								</RootDesc>

								<BorderArticle>
									<PreviewCard
										name={data[id]?.name}
										mask={data[id]?.mask}
										image={data[id]?.image}
									/>
								</BorderArticle>
							</div>

							<ul className={styles.videoContentBody}>
								<VideoField
									label={t('page.scenario.field.products')}
									value={data[id]?.product}
								/>
								<VideoField
									label={t('page.product.field.product_line')}
									value={data[id]?.line}
								/>
								<VideoField
									label={t('page.product.field.country')}
									value={data[id]?.country}
								/>
								<VideoField
									label={t('page.video.field.description')}
									value={data[id]?.description}
								/>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
