import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	language: '',
	isMobile: window.innerWidth < 768,
	width: window.innerWidth,
	navigateHistory: null
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setIsMobile(state, action) {
			state.isMobile = action.payload
		},
		setWidth(state, action) {
			state.width = action.payload
		},
		setLanguage(state, action) {
			state.language = action.payload
		},
		setNavigateHistory(state, action) {
			state.navigateHistory = action.payload
		},
	},
})

export const { setIsMobile, setNavigateHistory, setWidth, setLanguage } = settingsSlice.actions
export default settingsSlice.reducer
