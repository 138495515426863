import React from 'react'
import styles from './styles.module.scss'
import { H3 } from '../../titles/H3'
import { RootDesc } from '../../descriptions/RootDesc'
import { useTranslation } from 'react-i18next'

export const WarningData = ({ type }) => {
	const { t } = useTranslation()

	if (type === 'error') {
		return (
			<div className={styles.warningData}>
				<div className='text-[var(--red)]'>
					<H3>{t('server_error.table_error_title')}</H3>
				</div>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						{t('server_error.table_error_subtitle')}
					</span>
				</RootDesc>
			</div>
		)
	} else if (type === 'empty') {
		return (
			<div className={styles.warningData}>
				<H3>{t('server_error.table_empty_title')}</H3>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						{t('server_error.table_empty_subtitle')}
					</span>
				</RootDesc>
			</div>
		)
	} else {
		return (
			<div className={styles.warningData}>
				<H3>{t('server_error.table_empty_title')}</H3>

				<RootDesc>
					<span className='text-[var(--grey)]'>
						{t('server_error.table_empty_subtitle')}
					</span>
				</RootDesc>
			</div>
		)
	}
}
