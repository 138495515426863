import axios from 'axios'
import Cookies from 'js-cookie'
import { serialize } from 'object-to-formdata'

const $api = axios.create({
	withCredentials: true,
	baseURL: '',
	transformRequest: [
		(data, headers) => {
			if (data && typeof data === 'object' && !(data instanceof FormData)) {
				return serialize(data, {
					dotsForObjectNotation: false,
				})
			}

			return data
		},
	],
})

$api.interceptors.request.use(
	config => {
		const jwt = Cookies.get('jwt')

		if (jwt) {
			config.headers.Authorization = `Bearer ${jwt}`
		}

		return config
	},
	error => {
		return Promise.reject(error)
	}
)

$api.interceptors.response.use(
	config => {
		return config
	},
	async error => {
		const originalRequest = { ...error.config }
		originalRequest._isRetry = true

		if (
			error.response.status === 401 &&
			error.config &&
			!error.config._isRetry
		) {
			try {
				const rt = Cookies.get('refresh')

				if (rt) {
					const response = await $api.post(
						`/jwt`,
						{ rt },
						{
							withCredentials: true,
						}
					)

					Cookies.set('refresh', response.data.rt)
					Cookies.set('jwt', response.data.jwt)

					originalRequest.headers.Authorization = `Bearer ${Cookies.get('jwt')}`

					return $api.request(originalRequest)
				} else {
					throw new Error('Error!')
				}
			} catch (e) {
				console.log('NOT_AUTHORIZED')

				if (!originalRequest.url.includes('sign_in')) {
					window.location.href = '/'
				}
			}
		}

		return Promise.reject(error)
	}
)

export default $api
