import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { useTranslation } from 'react-i18next'

export const TableSelect = props => {
	const { t } = useTranslation()
	const { options, onSelect, activeOption } = props
	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState(activeOption)

	const selectRef = useRef(null)

	const toggleSelect = () => {
		setIsOpen(!isOpen)
	}

	const handleOptionClick = option => {
		setSelectedOption(option)
		onSelect(option)
		setIsOpen(false)
	}

	const handleClickOutside = event => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div ref={selectRef} className={styles.select}>
			<div className={styles.selectHeader} onClick={toggleSelect}>
				<RootDesc>
					<span>
						{t('table.select.show')} {selectedOption}
					</span>
				</RootDesc>

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>

				<RootDesc>
					<span>{t('table.select.entries')}</span>
				</RootDesc>
			</div>

			{isOpen && (
				<div className={styles.selectList}>
					<BorderArticle>
						<ul>
							{options.map(option => (
								<li
									key={option}
									onClick={() => handleOptionClick(option)}
									className={
										selectedOption === option ? styles.activeOption : ''
									}
								>
									<RootDesc>
										<span>{option}</span>
									</RootDesc>
								</li>
							))}
						</ul>
					</BorderArticle>
				</div>
			)}
		</div>
	)
}

export default TableSelect
