import React, { useState, useEffect, useRef } from 'react'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'

import styles from './styles.module.scss'

export const EditSelect = props => {
	const {
		width,
		activeOption,
		options,
		onSelect,
		multiSelect,
		searchPlaceholder,
	} = props

	const [isOpen, setIsOpen] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState(
		multiSelect ? activeOption : [activeOption]
	)
	const [searchTerm, setSearchTerm] = useState('')

	const selectRef = useRef(null)

	const toggleSelect = () => {
		setIsOpen(!isOpen)
	}

	const handleOptionClick = option => {
		if (multiSelect) {
			setSelectedOptions(prevSelected => {
				if (prevSelected.includes(option.name)) {
					return prevSelected.filter(item => item !== option.name)
				} else {
					return [...prevSelected, option.name]
				}
			})
		} else {
			setSelectedOptions([option.name])
		}

		onSelect(option)
		setIsOpen(false)
	}

	const handleClickOutside = event => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const filteredOptions = options.filter(option =>
		option.name.toLowerCase().includes(searchTerm.toLowerCase())
	)

	return (
		<div ref={selectRef} className={styles.select}>
			<div
				style={
					isOpen
						? { borderBottom: '1rem solid var(--blue)' }
						: { borderBottom: '1rem solid var(--grey)' }
				}
				className={styles.selectHeader}
				onClick={toggleSelect}
			>
				<RootDesc>
					<span>
						{multiSelect ? selectedOptions.join(', ') : selectedOptions[0]}
					</span>
				</RootDesc>

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>
			</div>

			{isOpen && (
				<div className={styles.selectList} style={{ maxWidth: `${width}rem` }}>
					<BorderArticle>
						<div className={styles.selectBody}>
							{searchPlaceholder && (
								<input
									type='text'
									placeholder={searchPlaceholder}
									value={searchTerm}
									onChange={e => setSearchTerm(e.target.value)}
									className={styles.searchInput}
								/>
							)}

							<ul>
								{filteredOptions.map(option => (
									<li
										style={
											!multiSelect && selectedOptions.includes(option.name)
												? { pointerEvents: 'none' }
												: {}
										}
										className={
											selectedOptions.includes(option.name)
												? styles.activeOption
												: ''
										}
										key={option.id}
										onClick={() => handleOptionClick(option)}
									>
										<SmallDesc>
											<span>{option.name}</span>
										</SmallDesc>
									</li>
								))}
							</ul>

							{filteredOptions.length === 0 && (
								<SmallDesc>
									<span className='text-[var(--grey)]'>No results</span>
								</SmallDesc>
							)}
						</div>
					</BorderArticle>
				</div>
			)}
		</div>
	)
}

export default EditSelect
