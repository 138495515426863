import React from 'react'
import { useSelector } from 'react-redux'

import { capitalize } from '@/helpers/helpers'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const LevelPopup = props => {
	const { t } = useTranslation()
	const { item, close } = props
	const { serverStatus } = useSelector(state => state.avatar)

	const descriptionLevelList = [
		{
			id: 0,
			text: t('popup.simulation.easy'),
		},
		{
			id: 1,
			text: t('popup.simulation.medium'),
		},
		{
			id: 2,
			text: t('popup.simulation.hard'),
		},
	]

	return (
		<div className={styles.levelPopup}>
			<h2>
				{capitalize(item?.name)} {t('table.level')}
			</h2>

			<H3>{t('popup.simulation.title')}</H3>

			<RootDesc>
				<span className='block text-center'>
					{descriptionLevelList[item?.id].text}
				</span>
			</RootDesc>

			{serverStatus === 'loading' ? (
				<div className={styles.spinner}>
					<RootIcon width={26} height={26} id={'spinner-upload'} />
				</div>
			) : (
				<RootButton
					txt={t('button.continue')}
					type={'button'}
					onClick={close}
				/>
			)}
		</div>
	)
}
