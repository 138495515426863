import React from 'react'
import { useSelector } from 'react-redux'

import { BorderArticle } from '../../general/BorderArticle'
import { RootIcon } from '../../icons/RootIcon'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

export const TableButton = ({ icon, color, label, action, item }) => {
	const { isMobile } = useSelector(state => state.settings)

	const handleClick = () => {
		console.log(action, ': ', item)
	}

	const isActionLink = action !== 'deactivate' && action !== 'delete'
	const isActionButton = action === 'deactivate' || action === 'delete'

	return (
		<div className={styles.tableButton}>
			<Link
				to={isActionLink ? `${action}/${item.id}` : undefined}
				onClick={isActionButton ? handleClick : undefined}
				style={{
					borderColor: color || 'var(--black)',
					color: color || 'inherit',
					'--color': color || 'transparent',
				}}
			>
				{icon && (
					<RootIcon
						width={isMobile ? 30 : 16}
						height={isMobile ? 30 : 16}
						id={icon}
					/>
				)}
			</Link>
			<BorderArticle>
				<i>{label}</i>
			</BorderArticle>
		</div>
	)
}
