import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearProductOptions,
	getCountries,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'
import {
	clearCreateUser,
	initialCreateUser,
	setCreateUser,
	setIsUserCreate,
	setError,
	postCreateUser,
} from '@/redux/slices/userSlices/createUserSlice'

import { isEqual } from '@/helpers/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components//ui/general/Spinner'
import { EditInput } from '@/components/ui/inputs/EditInput'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'
import UserImage from '@/components/ui/general/UserImage'

import photoImage from '@/assets/images/cards/photo.svg'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '../../../helpers/helpers'
import { FormError } from '../../ui/general/FormError'

export const UserCreateLayout = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { openPopup } = usePopup()

	const { isMobile } = useSelector(state => state.settings)
	const { createUser, serverStatus, error } = useSelector(
		state => state.createUser
	)
	const { countries, productLine } = useSelector(state => state.productOptions)

	const [photoFile, setPhotoFile] = useState(null)

	const roleList = [
		{ id: 0, name: t('role.admin'), value: 'Admin' },
		{ id: 1, name: t('role.manager'), value: 'Manager' },
		{ id: 2, name: t('role.rep'), value: 'Representative' },
	]

	const updateUserField = (fieldName, value) => {
		if (fieldName === 'role') {
			dispatch(
				setCreateUser({
					...createUser,
					product_line_id: '',
					country_id: '',
					[fieldName]: value,
				})
			)
		} else {
			dispatch(setCreateUser({ ...createUser, [fieldName]: value }))
		}
	}

	const handleClickImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const isFormValid = () => {
		const requiredFields = ['name', 'lastname', 'email', 'password', 'role']

		if (createUser.role !== 'Admin') {
			requiredFields.push('country_id', 'product_line_id')
		}

		return requiredFields.every(field => createUser[field] !== '')
	}

	const handleClickSave = async () => {
		if (!isFormValid()) {
			openPopup(<FillDataPopup />)

			return
		} else if (!isValidEmail(createUser.email)) {
			return dispatch(setError(t('server_error.email')))
		}

		const user = {
			user: {
				name: createUser.name,
				lastname: createUser.lastname,
				email: createUser.email,
				password: createUser.password,
				type: createUser.role,
				country_id: createUser.country_id,
				product_line_id: createUser.product_line_id,
				cover: photoFile,
			},
		}

		const resultAction = await dispatch(postCreateUser({ user }))

		if (postCreateUser.fulfilled.match(resultAction)) {
			openPopup(<SuccessfulSavePopup title={t('popup.save_data.user')} />)
			setPhotoFile(null)
			dispatch(clearCreateUser())
		} else {
			dispatch(setError('Save error!'))
		}
	}

	useEffect(() => {
		if (createUser && initialCreateUser) {
			dispatch(setIsUserCreate(!isEqual(createUser, initialCreateUser)))
		}
	}, [createUser, initialCreateUser, dispatch])

	useEffect(() => {
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearProductOptions())
			dispatch(clearCreateUser())
		}
	}, [dispatch])

	useEffect(() => {
		return () => {
			if (photoFile) {
				URL.revokeObjectURL(photoFile)
			}
		}
	}, [photoFile])

	return (
		<div className='w-[100%]'>
			<BorderArticle>
				<div className={styles.userWrapper}>
					<div className={styles.userHead}>
						<BackButton />
					</div>

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={`${styles.user} ${styles.userBig}`}>
							<div className={styles.userInfo}>
								<div className={styles.userName}>
									<EditInput
										placeholder={t('input.user_name_placeholder')}
										width={!isMobile ? 350 : 210}
										label='user-name'
										type='text'
										value={createUser?.name}
										onChange={e => updateUserField('name', e.target.value)}
									/>

									<ControlButton
										color={'var(--green)'}
										txt={t('button.save')}
										onClick={handleClickSave}
									/>

									{error !== '' && <FormError error={error} bottom={-25} />}
								</div>

								<UserImage
									photoFile={photoFile}
									photoImage={photoImage}
									onClick={handleClickImage}
								/>
							</div>

							<div className={styles.content}>
								<ul>
									<li>
										<RootDesc>
											<b className='block min-w-[200rem]'>
												{t('page.profile.field.last_name')}
												<sup>*</sup>
											</b>
										</RootDesc>

										<EditInput
											width={535}
											label={`user-lastname`}
											type='text'
											value={createUser?.lastname}
											onChange={e =>
												updateUserField('lastname', e.target.value)
											}
										/>
									</li>

									<li>
										<RootDesc>
											<b className='block min-w-[200rem]'>
												Email
												<sup>*</sup>
											</b>
										</RootDesc>

										<EditInput
											width={535}
											label={`user-email`}
											type='text'
											value={createUser?.email}
											onChange={e => updateUserField('email', e.target.value)}
										/>
									</li>

									<li>
										<RootDesc>
											<b className='block min-w-[200rem]'>
												{t('page.profile.field.password')}
												<sup>*</sup>
											</b>
										</RootDesc>

										<EditInput
											width={535}
											label={`user-password`}
											type='text'
											value={createUser?.password}
											onChange={e =>
												updateUserField('password', e.target.value)
											}
										/>
									</li>

									<li>
										<RootDesc>
											<b className='block min-w-[200rem]'>
												{t('page.profile.field.role')}
												<sup>*</sup>
											</b>
										</RootDesc>

										<EditSelect
											width={350}
											options={roleList}
											onSelect={selectedOption =>
												updateUserField('role', selectedOption?.value)
											}
										/>
									</li>

									{createUser?.role !== 'Admin' && (
										<>
											<li>
												<RootDesc>
													<b className='block min-w-[200rem]'>
														{t('page.profile.field.product_line')}
														<sup>*</sup>
													</b>
												</RootDesc>

												{productLine && productLine.length > 0 && (
													<EditSelect
														width={350}
														searchPlaceholder={t('input.product_line_search')}
														activeOption={createUser?.product_line}
														options={productLine}
														onSelect={selectedOption =>
															updateUserField(
																'product_line_id',
																selectedOption?.id
															)
														}
													/>
												)}
											</li>

											<li>
												<RootDesc>
													<b className='block min-w-[200rem]'>
														{t('page.profile.field.country')}
														<sup>*</sup>
													</b>
												</RootDesc>

												{countries && countries.length > 0 && (
													<EditSelect
														width={350}
														searchPlaceholder={t('input.country_search')}
														activeOption={createUser?.country}
														options={countries}
														onSelect={selectedOption =>
															updateUserField('country_id', selectedOption?.id)
														}
													/>
												)}
											</li>
										</>
									)}
								</ul>
							</div>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
