import React from 'react'
import styles from './styles.module.scss'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { Trans, useTranslation } from 'react-i18next'

export const AvatarErrorPopup = props => {
	const { onClickTryAgain, onClickExit } = props
	const { t } = useTranslation()

	return (
		<div className={styles.deletePopup}>
			<h2>
				<Trans i18nKey='popup.avatar_error.title'>
					{t('popup.avatar_error.title')}
				</Trans>
			</h2>

			<div className={styles.buttons}>
				<ControlButton
					color='var(--blue)'
					txt={t('button.again')}
					onClick={onClickTryAgain}
				/>

				<ControlButton
					color='var(--green)'
					txt={t('button.exit_simulation')}
					onClick={onClickExit}
				/>
			</div>
		</div>
	)
}
