import { useTranslation } from 'react-i18next'

export const useScoreValue = () => {
	const { t } = useTranslation()

	const currentScore = score => {
		switch (true) {
			case !score:
				return {
					grade: ``,
					// description: ``,
				}

			case score === 0:
				return {
					grade: t('score.unacceptable.grade'),
					// description: t('score.unacceptable.description'),
				}

			case score >= 1 && score <= 3:
				return {
					grade: t('score.poor.grade'),
					// description: t('score.poor.description'),
				}

			case score >= 4 && score <= 5:
				return {
					grade: t('score.fair.grade'),
					// description: t('score.fair.description'),
				}

			case score >= 6 && score <= 7:
				return {
					grade: t('score.good.grade'),
					// description: t('score.good.description'),
				}

			case score >= 8 && score <= 10:
				return {
					grade: t('score.excellent.grade'),
					// description: t('score.excellent.description'),
				}

			default:
				return {
					grade: ``,
					// description: ``,
				}
		}
	}

	return currentScore
}
