import $api from '../http'

export default class SessionsService {
	static async getScenarioSessions({ page, size }) {
		return $api.get(`/api/v1/scenario_sessions?page=${page}&page_size=${size}`)
	}

	static async getProductSessions({ page, size }) {
		return $api.get(`/api/v1/product_sessions?page=${page}&page_size=${size}`)
	}

	static async getLastActivity() {
		return $api.get(`/api/v1/ai_sessions/last_activity?page_size=10`)
	}

	static async viewScenarioSession({ id }) {
		return $api.get(`/api/v1/scenario_sessions/${id}`)
	}

	static async viewProductSession({ id }) {
		return $api.get(`/api/v1/product_sessions/${id}`)
	}
}
