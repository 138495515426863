import React from 'react'
import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const BrowserSupportPopup = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.browserPopup}>
			<h2>
				<Trans i18nKey='popup.browser.title'>{t('popup.browser.title')}</Trans>
			</h2>

			<h2>{t('popup.browser.description')}</h2>
		</div>
	)
}
