import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/min/moment-with-locales'
import {
	getProducts,
	setPage,
	setSort,
	setSize,
	setTotalPages,
	clearProducts,
} from '@/redux/slices/productSlices/productsSlice'
import { useTranslation } from 'react-i18next'

import TableLayout from '@/components/layouts/TableLayout'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { TableButton } from '@/components/ui/buttons/TableButton'

export const Products = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { demo } = useSelector(state => state.candidate)
	const { products, page, size, sort, totalPages, serverStatus, error } =
		useSelector(state => state.products)

	const handleClickDeactivateAll = () => {
		console.log('Deactivated check products!')
	}

	const handleCheckFilter = () => {
		console.log('Show inactive products!')
	}

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const controls = [
		{
			id: 0,
			button: (
				<ControlButton color='var(--green)' txt={t('button.add')} link={true} />
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={t('button.deactivate')}
					onClick={() => handleClickDeactivateAll()}
				/>
			),
		},
	]

	const columns = useMemo(() => {
		const baseColumns = [
			{ Header: t('table.name'), accessor: 'name', width: 275 },
			{ Header: t('table.product_line'), accessor: 'product_line', width: 310 },
			{
				Header: t('table.created'),
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					return <span>{moment(value).format('DD-MM-YYYY')}</span>
				},
				width: 280,
			},
			{ Header: t('table.status'), Cell: () => <>Created</>, width: 340 },
			{
				Header: t('table.actions'),
				Cell: ({ row }) => (
					<div
						style={demo ? { justifyContent: 'flex-end' } : {}}
						className='flex gap-[8rem] phone:gap-[40rem]'
					>
						<TableButton
							icon='view'
							color='var(--orange)'
							label={t('table.button.view')}
							action={'view'}
							item={row.original}
						/>

						{!demo && (
							<>
								<TableButton
									icon='edit'
									color='var(--blueLight)'
									label={t('table.button.edit')}
									action={'edit'}
									item={row.original}
								/>

								<TableButton
									icon='deactivate'
									color='var(--red)'
									label={t('table.button.deactivate')}
									action={'deactivate'}
									item={row.original}
								/>
							</>
						)}
					</div>
				),
				width: 210,
			},
		]

		if (!demo) {
			baseColumns.unshift({
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 50,
			})
		}

		return baseColumns
	}, [demo])

	useEffect(() => {
		if (Object.keys(sort).length !== 0) {
			dispatch(getProducts({ page, size }))
		}
	}, [page, size, sort])

	useEffect(() => {
		return () => {
			dispatch(clearProducts())
		}
	}, [location, dispatch])

	useEffect(() => {
		const firstAccessor = columns.find(column => column.accessor)

		dispatch(
			setSort({ name: firstAccessor.Header, value: firstAccessor.accessor })
		)
	}, [])

	return (
		<ScreenLayout
			controlsList={!demo && controls}
			title={t('page.products.title')}
		>
			<TableLayout
				serverStatus={serverStatus}
				serverError={error}
				nextPage={goToNextPage}
				previousPage={goToPreviousPage}
				totalPages={totalPages}
				dataSize={size}
				currentPage={page}
				dataSort={sort}
				selectSort={handleSelectSort}
				toPage={goToPage}
				selectSize={handleSelectSize}
				columns={columns}
				data={products}
				filterCheckbox={
					!demo && (
						<RootCheckbox
							onChange={() => handleCheckFilter()}
							label={t('page.products.checkbox_filter')}
						/>
					)
				}
			/>
		</ScreenLayout>
	)
}
