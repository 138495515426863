import React from 'react'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const Feedback = () => {
	const { t } = useTranslation()

	const feedbackList = [
		{
			id: 0,
			title: 'Overall Communication Analysis:',
			description:
				'You engage doctors effectively and maintain professionalism throughout your visits. You follow the cycle book well and use visual aids and real-life examples to make information clearer.',
		},
		{
			id: 1,
			title: 'Areas for Improvement:',
			description: (
				<ol>
					<li>
						<RootDesc>
							<span>
								<b>Conversation Balance:</b> Work on encouraging more two-way
								dialogue by actively listening and inviting the doctor’s
								feedback.
							</span>
						</RootDesc>
					</li>

					<li>
						<RootDesc>
							<span>
								<b>Time Management:</b> Aim to be more concise to respect the
								doctor’s time and focus on key points.
							</span>
						</RootDesc>
					</li>

					<li>
						<RootDesc>
							<span>
								<b>Adaptability:</b> Customize your approach to each doctor’s
								specific needs rather than strictly following the script.
							</span>
						</RootDesc>
					</li>
				</ol>
			),
		},
		{
			id: 2,
			title: 'Effective Strategies:',
			description: (
				<ol>
					<li>
						<RootDesc>
							<span>
								<b>Engagement:</b> Start conversations with relevant topics to
								capture and keep the doctor’s attention.
							</span>
						</RootDesc>
					</li>

					<li>
						<RootDesc>
							<span>
								<b>Use of Visual Aids:</b> Continue using visuals and patient
								stories to simplify complex information.
							</span>
						</RootDesc>
					</li>

					<li>
						<RootDesc>
							<span>
								<b>Consistency in Messaging:</b> Maintain your structured
								approach while integrating more personalized elements.
							</span>
						</RootDesc>
					</li>
				</ol>
			),
		},
	]

	return (
		<div className={styles.feedback}>
			<div className={styles.feedbackHead}>
				<H3>{t('page.profile.feedback.title')}</H3>
			</div>

			<ul className={styles.feedbackList}>
				{feedbackList &&
					feedbackList.map(item => (
						<li key={item?.id}>
							<h2>{item?.title}</h2>

							<RootDesc>
								<span>{item?.description}</span>
							</RootDesc>
						</li>
					))}
			</ul>
		</div>
	)
}
