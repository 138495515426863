import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMobile, setWidth, setLanguage, setNavigateHistory } from '@/redux/slices/settingsSlice'
import { checkAuth } from '@/redux/slices/candidateSlice'
import moment from 'moment/min/moment-with-locales'
import Cookies from 'js-cookie'

import { Login } from '@/pages/Login'
import { AdminPanel } from '@/pages/AdminPanel'
import { UserPanel } from '@/pages/UserPanel'
import { Simulations } from '@/pages/Simulations'
import { Privacy } from '@/pages/Policy/Privacy'
import { Terms } from '@/pages/Policy/Terms'
import { ChatGame } from '@/pages/ChatGame'
import { Spinner } from '../ui/general/Spinner'

import 'moment/locale/ru'
import '@/styles/app.scss'

const App = () => {
	const { language, navigateHistory } = useSelector(state => state.settings)
	const { auth, role } = useSelector(state => state.candidate)
	const dispatch = useDispatch()
	const location = useLocation()

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 768))
			dispatch(setWidth(window.innerWidth))
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		window.addEventListener('orientationchange', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('orientationchange', handleResize)
		}
	}, [dispatch])

	useEffect(() => {
		const languageAttribute = document.querySelector('[data-ava-lang]')

		if (languageAttribute) {
			const languageValue = languageAttribute.getAttribute('data-ava-lang')

			Cookies.set('language', languageValue)
			dispatch(setLanguage(languageValue))
		} else {
			Cookies.set('language', 'en')
			dispatch(setLanguage('en'))
		}

		if (!auth) dispatch(checkAuth())
	}, [])

	useEffect(() => {
		moment.locale(language)
	}, [language])

	useEffect(()=>{
		if(!navigateHistory) {
			dispatch(setNavigateHistory(location.pathname))
		}
	})

	return (
		<Suspense fallback={<Spinner width={80} height={80} />}>
			<Routes>
				{auth ? (
					<>
						<Route
							path='/'
							element={
								role === 'Representative' ? (
									navigateHistory && navigateHistory !== '/' ? <Navigate to={navigateHistory} /> : <Navigate to='/simulations/call' />
								) : (
									navigateHistory && navigateHistory !== '/' ? <Navigate to={navigateHistory} /> : <Navigate to='/admin-panel/dashboard' />
								)
							}
						/>
						<Route
							path='/login'
							element={
								role === 'Representative' ? (
									navigateHistory && navigateHistory !== '/login' ? <Navigate to={navigateHistory} /> : <Navigate to='/simulations/call' />
								) : (
									navigateHistory && navigateHistory !== '/login' ? <Navigate to={navigateHistory} /> : <Navigate to='/admin-panel/dashboard' />
								)
							}
						/>

						<Route path='/simulations/*' element={<Simulations />} />
						<Route
							path='/chat-game/:level/:scenario_id'
							element={<ChatGame />}
						/>

						{(role === 'Admin' || role === 'Manager') && (
							<Route path='/admin-panel/*' element={<AdminPanel />} />
						)}

						{role === 'Representative' && (
							<Route path='/user-panel/*' element={<UserPanel />} />
						)}
					</>
				) : (
					<>
						<Route path='/' element={<Navigate to='/login' />} />
						<Route path='/login' element={<Login />} />
						<Route path='/simulations/*' element={<Navigate to='/login' />} />
						<Route path='/admin-panel/*' element={<Navigate to='/login' />} />
						<Route path='/user-panel/*' element={<Navigate to='/login' />} />
						<Route
							path='/chat-game/:level/:scenario_id'
							element={<Navigate to='/login' />}
						/>
					</>
				)}

				<Route path='/privacy' element={<Privacy />} />
				<Route path='/terms' element={<Terms />} />
				<Route path='*' element={<Login />} />
			</Routes>
		</Suspense>
	)
}

export default App
