import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize } from '../../helpers/helpers'

const LEVELS = [
	{
		// 10 minuts = 600000/7 minuts = 420000}
		id: 0,
		value: 'easy',
		remainingScenarioTime: 600000,
		remainingProductTime: 420000,
	},
	{
		// 8 minuts = 480000/9 minuts = 540000 }
		id: 1,
		value: 'medium',
		remainingScenarioTime: 480000,
		remainingProductTime: 540000,
	},
	{
		// 5 minuts = 300000/10 minuts = 600000 }
		id: 2,
		value: 'hard',
		remainingScenarioTime: 300000,
		remainingProductTime: 600000,
	},
]

export const useLevelOrder = () => {
	const { t } = useTranslation()

	const levels = useMemo(() => {
		return LEVELS.map(level => ({
			...level,
			name: t(`level.${level.value}`),
		}))
	}, [t])

	const currentLevel = level => {
		switch (level) {
			case 'easy':
				return capitalize(t('level.easy'))
			case 'medium':
				return capitalize(t('level.medium'))
			case 'hard':
				return capitalize(t('level.hard'))
			default:
				return ''
		}
	}

	const [currentLevels, setCurrentLevels] = useState(levels)

	return { currentLevel, levels: currentLevels }
}
