import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Timer } from './Timer'
import { useChatTime } from '@/hooks/ChatTime'
import { Header } from '@/components/layouts/Header'
import { Controls } from './Controls'
import { Chat } from './Chat'
import { RootButton } from '@/components/ui/buttons/RootButton'
import AvatarComponent from './AvatarComponent'
import AvatarImage from './AvatarImage'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const ChatGame = () => {
	const [showAvatar, setShowAvatar] = useState(false)
	const { isMobile } = useSelector(state => state.settings)
	const { error, scenario_session_id, product_session_id, avatarToken } =
		useSelector(state => state.avatar)
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	const remainingTime = scenario_session_id
		? location?.state?.level?.remainingScenarioTime
		: location?.state?.level?.remainingProductTime

	const [chatTime, setChatTimeState, restart] = useChatTime(remainingTime)

	useEffect(() => {
		if (!scenario_session_id && !product_session_id && avatarToken === '') {
			navigate('/simulations')
		} else if (remainingTime > 0) {
			setChatTimeState(remainingTime)
		}
	}, [scenario_session_id, product_session_id, remainingTime])

	return (
		<div className={styles.gameWrapper}>
			<Header />

			<div className='container-big phone:p-0'>
				<div className={styles.game}>
					<div className={styles.doctor}>
						{window.location.host.includes('test') ||
						window.location.host.includes('localhost') ? (
							<>
								<div className={styles.btn}>
									<RootButton
										onClick={() => setShowAvatar(!showAvatar)}
										txt={
											isMobile ? (
												<RootIcon width={24} height={24} id={'show-pass'} />
											) : (
												t('button.show_avatar')
											)
										}
									/>
								</div>
								{showAvatar ? (
									error === '' ? (
										<>
											<AvatarComponent
												restartTime={restart}
												time={chatTime}
												setTime={setChatTimeState}
											/>
											<Controls />
										</>
									) : (
										<AvatarImage
											restartTime={restart}
											time={chatTime}
											setTime={setChatTimeState}
										/>
									)
								) : (
									<AvatarImage
										restartTime={restart}
										time={chatTime}
										setTime={setChatTimeState}
									/>
								)}
							</>
						) : error === '' ? (
							<>
								<AvatarComponent
									restartTime={restart}
									time={chatTime}
									setTime={setChatTimeState}
								/>
								<Controls />
							</>
						) : (
							<AvatarImage
								restartTime={restart}
								time={chatTime}
								setTime={setChatTimeState}
							/>
						)}
					</div>

					<Timer time={chatTime} />

					<div className='relative z-10 bottom-[50rem] phone:w-[100%] phone:bottom-0 phone:flex phone:items-center justify-center'>
						<Chat time={chatTime} />
					</div>
				</div>
			</div>
		</div>
	)
}
