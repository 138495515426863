import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearAvatarState,
	createScenarioSession,
	setIsSessionActive,
	getQuestion,
	createProductSession,
	setProductSessionId,
	setScenarioSessionId,
	setScore,
} from '@/redux/slices/avatarSlices/avatarSlice'
import { useTranslation } from 'react-i18next'

import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import doctorImage from '@/assets/images/general/doctor.png'
import styles from './styles.module.scss'

function AvatarImage({ time, setTime, restartTime }) {
	const {
		messages,
		product_session_id,
		scenario_session_id,
		score,
		sessionState,
		questions,
		avatarToken,
	} = useSelector(state => state.avatar)

	const { t } = useTranslation()
	const { openPopup, closePopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const level = location.state?.level
	const params = useParams()

	const handleCloseLevelPopup = async () => {
		if (product_session_id) {
			await dispatch(getQuestion({ id: product_session_id }))
		}

		closePopup()
	}

	const handleTryAgain = async () => {
		await dispatch(clearAvatarState())
		await dispatch(setScore(null))

		if (product_session_id) {
			await dispatch(
				createProductSession({
					level: params.level,
					product_id: params.scenario_id,
				})
			)

			await dispatch(getQuestion({ id: product_session_id }))

			restartTime(new Date(Date.now() + level.remainingProductTime), false)
		} else {
			await dispatch(
				createScenarioSession({
					level: params.level,
					scenario_id: params.scenario_id,
				})
			)

			restartTime(new Date(Date.now() + level.remainingScenarioTime), false)
		}

		await dispatch(setIsSessionActive(true))
		closePopup()
	}

	const handleQuit = async () => {
		await dispatch(setScore(null))
		await dispatch(clearAvatarState())
		await dispatch(setProductSessionId(null))
		await dispatch(setScenarioSessionId(null))

		navigate(-1)
		closePopup()
	}

	const handleClickQuitBtn = async () => {
		if (messages.length > 1) {
			restartTime(Date.now(), false)
		} else {
			navigate(-1)
			dispatch(setProductSessionId(null))
			dispatch(setScenarioSessionId(null))
		}
	}

	useEffect(() => {
		if (
			(scenario_session_id && score === 0) ||
			(scenario_session_id && sessionState === 'ended') ||
			time === 0 ||
			questions === 0
		) {
			setTime(0)
			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		}
	}, [time, questions, score, sessionState, scenario_session_id])

	useEffect(() => {
		dispatch(setIsSessionActive(true))

		openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
			closeButton: false,
		})

		return () => {
			closePopup()
			dispatch(clearAvatarState())
		}
	}, [])

	useEffect(() => {
		return () => {
			if (avatarToken === '') {
				closePopup()
				dispatch(clearAvatarState())
				navigate('/simulations')
			}
		}
	}, [])

	return (
		<>
			<img className={styles.doctorImage} src={doctorImage} alt='doctor' />

			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>

				{!isMobile && (
					<RootDesc>
						<span>{t('button.exit')}</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default AvatarImage
