import React from 'react'
import styles from './styles.module.scss'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { Trans, useTranslation } from 'react-i18next'

export const UnsavedPopup = props => {
	const { onClickYes, onClickNo } = props
	const { t } = useTranslation()

	return (
		<div className={styles.unsavedPopup}>
			<h2>
				<Trans i18nKey='popup.unsaved.subtitle'>
					{t('popup.unsaved.subtitle')}
				</Trans>
			</h2>

			<div className={styles.buttons}>
				<ControlButton
					color='var(--red)'
					txt={t('button.yes_exit')}
					onClick={onClickYes}
				/>

				<ControlButton
					color='var(--green)'
					txt={t('button.no_stay')}
					onClick={onClickNo}
				/>
			</div>
		</div>
	)
}
