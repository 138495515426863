import React from 'react'

import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { PreviewCard } from '@/components/ui/general/PreviewCard'

import styles from './styles.module.scss'

export const SimulationsLayout = ({ cards, title }) => {
	return (
		<div className='container-big phone:p-0'>
			<div className={styles.simulationsWrapper}>
				<BorderArticle>
					<div className={styles.simulations}>
						<H3>{title}</H3>

						<ul className={styles.simulationsList}>
							{cards &&
								cards.map(item => (
									<li key={item?.id}>
										<PreviewCard
											due_date={item?.due_date}
											levelList={true}
											name={item?.name}
											image={item?.cover}
											until={item?.until}
											mask={item?.mask}
											id={item?.id}
											mandatory={item?.mandatory}
										/>
									</li>
								))}
						</ul>
					</div>
				</BorderArticle>
			</div>
		</div>
	)
}
