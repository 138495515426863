import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setName,
	setEmail,
	clearHelpForm,
	setDescription,
	setError,
	setAgree,
	helpRequest,
} from '@/redux/slices/requestSlices/helpSlice'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'

import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const HelpPopup = () => {
	const userName = useSelector(state => state.candidate.name)
	const userEmail = useSelector(state => state.candidate.email)
	const { auth } = useSelector(state => state.candidate)
	const { name, email, description, agree, error, serverStatus } = useSelector(
		state => state.help
	)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (auth) {
			if (!description || !userName || !userEmail) {
				dispatch(setError(t('server_error.fields')))
				return
			}

			const resultAction = await dispatch(
				helpRequest({ description, name: userName, email: userEmail })
			)

			if (helpRequest.fulfilled.match(resultAction)) {
				dispatch(clearHelpForm())
			} else {
				dispatch(setError(t('server_error.submit_data')))
			}
		} else {
			if (!description || !name || !email) {
				dispatch(setError(t('server_error.fields')))
				return
			}

			const resultAction = await dispatch(
				helpRequest({ description, name, email })
			)

			if (helpRequest.fulfilled.match(resultAction)) {
				dispatch(clearHelpForm())
			} else {
				dispatch(setError(t('server_error.submit_data')))
			}
		}
	}

	useEffect(() => {
		dispatch(setError(''))
	}, [name, email, description, agree])

	useEffect(() => {
		return () => {
			dispatch(clearHelpForm())
		}
	}, [])

	return (
		<div className={styles.help}>
			<H3>{t('popup.help.title')}</H3>

			<RootDesc>
				<span>
					<Trans i18nKey='popup.help.subtitle'>
						{t('popup.help.subtitle')}
					</Trans>
				</span>
			</RootDesc>

			<form onSubmit={handleSubmit} className={styles.helpForm}>
				{location.pathname.includes('login') && (
					<>
						<div className={styles.input}>
							<RootInput
								width={500}
								label={t('input.name')}
								type={'text'}
								value={name}
								required={true}
								onChange={e => dispatch(setName(e.target.value))}
								disabled={serverStatus === 'loading' && true}
							/>
						</div>

						<div className={styles.input}>
							<RootInput
								width={500}
								label={'Email'}
								type={'email'}
								value={email}
								required={true}
								onChange={e => dispatch(setEmail(e.target.value))}
								disabled={serverStatus === 'loading' && true}
							/>
						</div>
					</>
				)}

				<div className={styles.input}>
					<RootTextarea
						label={<Trans i18nKey='input.problem'>{t('input.problem')}</Trans>}
						type={'text'}
						value={description}
						required={true}
						onChange={e => dispatch(setDescription(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				{location.pathname.includes('login') ? (
					<>
						<div className={styles.helpCheckbox}>
							<RootCheckbox
								fontSize={14}
								checked={agree}
								onChange={e => dispatch(setAgree(e.target.checked))}
								label={
									<>
										<Trans i18nKey='input.privacy_checkbox_label'>
											{t('input.privacy_checkbox_label')}
										</Trans>{' '}
										<Link onClick={() => closePopup()} to={'/privacy'}>
											{t('input.privacy_checkbox_link')}
										</Link>
									</>
								}
							/>
						</div>

						<RootButton
							txt={t('button.submit')}
							type={'submit'}
							disabled={
								!description ||
								!email ||
								!name ||
								!agree ||
								serverStatus === 'loading'
							}
						/>
					</>
				) : (
					<RootButton
						txt={'Submit'}
						type={'submit'}
						disabled={!description || serverStatus === 'loading'}
					/>
				)}

				{error !== '' && <FormError error={error} bottom={105} />}

				{serverStatus === 'loading' && <Spinner width={50} height={50} />}
			</form>
		</div>
	)
}
