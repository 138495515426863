import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

export const ControlButton = props => {
	const { txt, link, iconId, type, onClick, disabled, color, hoverColor } =
		props

	if (!link) {
		return (
			<button
				className={styles.controlButton}
				type={type || 'button'}
				onClick={onClick}
				disabled={disabled}
				style={
					!disabled
						? {
								borderColor: color || 'transparent',
								color: color || 'inherit',
								'--color': color || 'transparent',
								'--hoverColor': hoverColor || 'var(--white)',
						  }
						: {}
				}
			>
				{iconId && <RootIcon width={16} height={16} id={iconId} />}

				{txt && (
					<SmallDesc>
						<span>{txt}</span>
					</SmallDesc>
				)}
			</button>
		)
	} else {
		return (
			<Link
				to={'create'}
				className={styles.controlButton}
				disabled={disabled}
				style={{
					borderColor: color || 'var(--black)',
					color: color || 'inherit',
					'--color': color || 'transparent',
					'--hoverColor': hoverColor || 'var(--white)',
				}}
			>
				{iconId && <RootIcon width={16} height={16} id={iconId} />}

				{txt && (
					<SmallDesc>
						<span>{txt}</span>
					</SmallDesc>
				)}
			</Link>
		)
	}
}
