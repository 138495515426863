import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { setTypeId } from '@/redux/slices/sessionSlices/sessionsSlice'

import { H2 } from '@/components/ui/titles/H2'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const ScreenLayout = props => {
	const { switchView, controlsList, title, children } = props
	const { typeId } = useSelector(state => state.sessions)
	const { isMobile } = useSelector(state => state.settings)
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef(null)

	const dispatch = useDispatch()

	const sessionTableList = [
		{
			id: 0,
			name: t('page.simulations.tab.call'),
		},
		{
			id: 1,
			name: t('page.simulations.tab.product'),
		},
	]

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	const handleClickTab = item => {
		dispatch(setTypeId(item.id))
		setIsOpen(false)
	}

	const switchTab = () => {
		switch (typeId) {
			case 0:
				return { transform: 'translateX(0)', left: '0' }
			case 1:
				return { transform: 'translateX(-100%)', left: '100%' }
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.screenWrapper}>
			<BorderArticle>
				<div className='container-small'>
					<div className={styles.screen}>
						<div className={styles.head}>
							<H2>{title}</H2>

							{controlsList && (
								<ul className={styles.headControls}>
									{controlsList.map(item => (
										<li key={item?.id}>{item?.button}</li>
									))}
								</ul>
							)}

							{switchView &&
								(!isMobile ? (
									<BorderArticle>
										<div className={styles.switch}>
											<label htmlFor='switch-view'>
												<i style={switchTab()}></i>

												{sessionTableList &&
													sessionTableList.map(item => (
														<div
															key={item?.id}
															onClick={() => handleClickTab(item)}
															style={
																item?.id === typeId
																	? {
																			pointerEvents: 'none',
																	  }
																	: { pointerEvents: 'all' }
															}
														>
															<RootDesc>
																<b
																	style={
																		item?.id === typeId
																			? {
																					color: 'var(--white)',
																			  }
																			: { color: 'var(--black)' }
																	}
																>
																	{item?.name}
																</b>
															</RootDesc>
														</div>
													))}
											</label>
										</div>
									</BorderArticle>
								) : (
									<div className={styles.dropdown} ref={dropdownRef}>
										<div
											className={styles.dropdownHeader}
											onClick={toggleDropdown}
										>
											<H3>{sessionTableList[typeId]?.name}</H3>

											<i
												style={
													isOpen
														? { transform: 'rotate(180deg)' }
														: { transform: 'rotate(0deg)' }
												}
											></i>
										</div>

										{isOpen && (
											<div className={styles.dropdownList}>
												<BorderArticle>
													<ul>
														{sessionTableList &&
															sessionTableList
																.filter(item => item?.id !== typeId)
																.map(item => (
																	<li
																		key={item?.id}
																		onClick={() => handleClickTab(item)}
																	>
																		<RootDesc>
																			<span>{item?.name}</span>
																		</RootDesc>
																	</li>
																))}
													</ul>
												</BorderArticle>
											</div>
										)}
									</div>
								))}
						</div>

						{children}
					</div>
				</div>
			</BorderArticle>
		</div>
	)
}
