import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { H3 } from '@/components/ui/titles/H3'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { useDispatch, useSelector } from 'react-redux'

import {
	clearCandidate,
	setData,
	setReason,
	setError,
	setServerStatus,
} from '@/redux/slices/changeRequestSlice'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { Trans, useTranslation } from 'react-i18next'

export const ChangeRequestPopup = () => {
	const { t } = useTranslation()
	const { data, reason, error, serverStatus } = useSelector(
		state => state.change
	)
	const dispatch = useDispatch()

	const handleSubmit = e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!data || !reason) {
			dispatch(setError(t('server_error.fields')))
			return
		}

		dispatch(setServerStatus('loading'))

		const timer = setTimeout(() => {
			dispatch(setServerStatus(''))
			dispatch(clearCandidate())
		}, 3000)

		return () => {
			clearTimeout(timer)
		}
	}

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				dispatch(setError(''))
			}, 3000)

			return () => clearTimeout(timer)
		}
	}, [error, dispatch])

	return (
		<div className={styles.change}>
			<H3>{t('popup.change_req.title')}</H3>

			<form onSubmit={handleSubmit} className={styles.changeForm}>
				<div className={styles.input}>
					<RootInput
						width={500}
						label={
							<Trans i18nKey='input.which_change'>
								{t('input.which_change')}
							</Trans>
						}
						type={'text'}
						value={data}
						required={true}
						onChange={e => dispatch(setData(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<div className={styles.input}>
					<RootInput
						width={500}
						label={
							<Trans i18nKey='input.reason_change'>
								{t('input.reason_change')}
							</Trans>
						}
						type={'text'}
						value={reason}
						required={true}
						onChange={e => dispatch(setReason(e.target.value))}
						disabled={serverStatus === 'loading' && true}
					/>
				</div>

				<ControlButton
					color={
						!data || !reason || serverStatus === 'loading'
							? 'var(--grey)'
							: 'var(--blue)'
					}
					txt={t('button.send_req')}
					type={'submit'}
					disabled={!data || !reason || serverStatus === 'loading'}
				/>
			</form>
		</div>
	)
}
