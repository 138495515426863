import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
	setError,
	setCreateProduct,
	setIsProductCreate,
	initialCreateProduct,
	postCreateProduct,
	clearCreateProduct,
} from '@/redux/slices/productSlices/createProductSlice'
import {
	getCountries,
	getProductLine,
	clearProductOptions,
} from '@/redux/slices/productSlices/productOptionsSlice'
import { Trans, useTranslation } from 'react-i18next'

import { isEqual, capitalize } from '@/helpers/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { UploadFilePopup } from '@/popups/uploadPopups/UploadFilePopup'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { Spinner } from '@/components//ui/general/Spinner'
import { MaskList } from '@/components/ui/general/MaskList'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { DocumentPreview } from '@/components/ui/general/DocumentPreview'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { useLevelOrder } from '@/hooks/LevelOrder'

import styles from './styles.module.scss'

export const ProductCreateLayout = () => {
	const { levels } = useLevelOrder()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { openPopup } = usePopup()

	const { isMobile } = useSelector(state => state.settings)
	const { countries, productLine } = useSelector(state => state.productOptions)
	const { createProduct, serverStatus } = useSelector(
		state => state.createProduct
	)

	const [fileList, setFileList] = useState([])
	const [photoFile, setPhotoFile] = useState(null)

	const productRef = useRef()

	const updateProductField = useCallback(
		(fieldType, value) => {
			dispatch(
				setCreateProduct({
					...createProduct,
					product: {
						...createProduct.product,
						[fieldType]: value,
					},
				})
			)
		},
		[createProduct, dispatch]
	)

	const updateProductVersionField = useCallback(
		(fieldType, value) => {
			dispatch(
				setCreateProduct({
					...createProduct,
					product_version: {
						...createProduct.product_version,
						[fieldType]: value,
					},
				})
			)
		},
		[createProduct, dispatch]
	)

	const handleClickCover = useCallback(
		color => {
			updateProductField('mask', color)
		},
		[updateProductField]
	)

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickUploadFile = level => {
		openPopup(
			<UploadFilePopup
				fileList={fileList}
				setFileList={setFileList}
				level={level.name}
			/>
		)
	}

	const handleClickRemoveFile = (fileName, level) => {
		setFileList(prevFileList =>
			prevFileList.filter(
				file => !(file.level === level && file.file.name === fileName)
			)
		)
	}

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
	}

	const handleClickSave = async () => {
		if (
			!groupedFiles.some(file => file.level === 'easy') ||
			!createProduct.product.mask ||
			!createProduct.product.name ||
			!createProduct.product.country_id ||
			!createProduct.product.product_line_id
		) {
			openPopup(<FillDataPopup />)
		} else {
			const product = {
				product: {
					name: createProduct.product.name,
					country_id: createProduct.product.country_id,
					product_line_id: createProduct.product.product_line_id,
					cover: photoFile,
					mask: createProduct.product.mask,
				},
				product_version: {
					questions: createProduct.product_version.questions,
					files: fileList,
				},
			}

			const resultAction = await dispatch(postCreateProduct({ product }))

			if (postCreateProduct.fulfilled.match(resultAction)) {
				openPopup(<SuccessfulSavePopup title={t('popup.save_data.product')} />)
				setFileList([])
				setPhotoFile(null)
				dispatch(clearCreateProduct())
			} else {
				dispatch(setError('Save error!'))
			}
		}
	}

	const groupedFiles = levels
		.map(level => ({
			level: level.value,
			file: fileList.filter(file => file.level === level.value),
		}))
		.filter(item => item.file.length > 0)

	useEffect(() => {
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearCreateProduct())
			dispatch(clearProductOptions())
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(setIsProductCreate(!isEqual(createProduct, initialCreateProduct)))
	}, [createProduct, dispatch])

	useEffect(() => {
		return () => {
			if (photoFile) {
				URL.revokeObjectURL(photoFile)
			}
		}
	}, [photoFile])

	useEffect(() => {
		if (productRef.current && isMobile) {
			productRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={productRef} className={styles.productWrapper}>
			<BorderArticle>
				<div className={styles.product}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.productContent}>
							<div className={styles.productContentHeadWrapper}>
								<div className={styles.productEditContent}>
									<div className={styles.productContentHead}>
										<div className='flex items-center gap-[16rem]'>
											<EditInput
												placeholder={t('input.product_name_placeholder')}
												width={!isMobile ? 380 : 210}
												label='product-name'
												type='text'
												value={createProduct?.product?.name}
												onChange={e =>
													updateProductField('name', e.target.value)
												}
											/>

											<ControlButton
												color='var(--green)'
												txt={t('button.save')}
												onClick={handleClickSave}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>
													{t('page.scenario.field.background')}
													<sup>*</sup>
												</b>
											</RootDesc>

											<MaskList onClickCover={handleClickCover} />
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>
													{t('page.scenario.field.image')}
												</b>
											</RootDesc>

											<div className='flex flex-col items-start gap-[16rem]'>
												{photoFile && (
													<DocumentPreview
														name={photoFile?.name}
														onClickCross={handleClickRemovePhoto}
													/>
												)}

												<ControlButton
													color='var(--violetLight)'
													txt={t('button.upload_image')}
													onClick={handleClickUploadImage}
												/>
											</div>
										</div>
									</div>

									<div className='flex flex-col gap-[24rem] items-start relative z-[1]'>
										<RootDesc>
											<b>{t('page.scenario.field.preview')}</b>
										</RootDesc>

										<PreviewCard
											name={createProduct?.product?.name}
											mask={createProduct?.product?.mask}
											image={
												photoFile ? URL.createObjectURL(photoFile) : undefined
											}
										/>
									</div>
								</div>
							</div>

							<ul className={`${styles.productContentBody} ${styles.editBody}`}>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.product.field.country')}
											<sup>*</sup>
										</b>
									</RootDesc>

									{countries.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder={t('input.country_search')}
												activeOption={createProduct?.product?.country}
												options={countries}
												onSelect={selectedOption =>
													updateProductField('country_id', selectedOption.id)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.product.field.product_line')}
											<sup>*</sup>
										</b>
									</RootDesc>

									{productLine.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder={t('input.product_line_search')}
												activeOption={createProduct?.product?.product_line}
												options={productLine}
												onSelect={selectedOption =>
													updateProductField(
														'product_line_id',
														selectedOption?.id
													)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											<Trans i18nKey='page.product.field.documents'>
												{t('page.product.field.documents')}
											</Trans>
										</b>
									</RootDesc>

									<div className={styles.productLevelList}>
										{levels &&
											levels.map(level => (
												<div key={level.id} className={styles.productLevel}>
													<RootDesc>
														<b className='block w-[200rem]'>
															{capitalize(level.name)}{' '}
															{t('page.product.field.level')}
															{level.value === 'easy' && <sup>*</sup>}
														</b>
													</RootDesc>

													<div className={styles.productDocuments}>
														{groupedFiles &&
															groupedFiles.length > 0 &&
															groupedFiles.map(
																item =>
																	item?.level === level.value &&
																	item?.file.map(file => (
																		<div key={uuidv4()}>
																			<DocumentPreview
																				level={level.value}
																				name={file?.file?.name}
																				onClickCross={(name, level) =>
																					handleClickRemoveFile(name, level)
																				}
																			/>
																		</div>
																	))
															)}

														<ControlButton
															color='var(--violetLight)'
															txt={t('button.upload_doc')}
															onClick={() => handleClickUploadFile(level)}
														/>
													</div>
												</div>
											))}
									</div>
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											<Trans i18nKey='page.product.field.questions'>
												{t('page.product.field.questions')}
											</Trans>
										</b>
									</RootDesc>

									<RootTextarea
										type={'text'}
										value={createProduct?.product_version?.questions}
										onChange={e =>
											updateProductVersionField('questions', e.target.value)
										}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
