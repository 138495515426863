import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersService from '@/services/UsersService'

export const getUsers = createAsyncThunk('users', async ({ page, size }) => {
	try {
		const response = await UsersService.getUsers({ page, size })

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

export const getAllUsers = createAsyncThunk('all-users', async () => {
	try {
		const response = await UsersService.getAllUsers()

		return response.data
	} catch (err) {
		throw new Error('Unauthorized!')
	}
})

const initialState = {
	users: [],
	page: 1,
	size: 5,
	sort: {},
	totalPages: 1,
	error: '',
	serverStatus: '',
}

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setPage(state, action) {
			state.page = action.payload
		},
		setSize(state, action) {
			state.size = action.payload
		},
		setSort(state, action) {
			state.sort = action.payload
		},
		setTotalPages(state, action) {
			state.totalPages = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearUsers() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getUsers.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.users = action.payload.data
				state.totalPages = action.payload.total
					? Math.ceil(action.payload.total / state.size)
					: state.totalPages
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getUsers.rejected, state => {
				state.error = 'Don`t get users!'
				state.serverStatus = 'error'
			})

			.addCase(getAllUsers.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.users = action.payload.data
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getAllUsers.rejected, state => {
				state.error = 'Don`t get users!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setPage,
	setSort,
	clearUsers,
	setTotalPages,
	setError,
	setSize,
	setServerStatus,
} = usersSlice.actions
export default usersSlice.reducer
