import { useState, useEffect } from 'react'
import moment from 'moment/min/moment-with-locales'
import { capitalize } from '@/helpers/helpers'
import { isEqual } from '../../helpers/helpers'

export const useMonths = () => {
	const currentYear = moment().year()
	const currentMonth = moment().month()

	const getDaysInMonth = (year, month) => {
		return Array.from(
			{ length: moment().year(year).month(month).daysInMonth() },
			(_, i) =>
				moment()
					.year(year)
					.month(month)
					.date(i + 1)
					.format('DD')
		)
	}

	const [months, setMonths] = useState([])
	const [thisMonth, setThisMonth] = useState({})
	const [prevMonth, setPrevMonth] = useState({})
	const [daysInThisMonth, setDaysInThisMonth] = useState([])
	const [daysInPrevMonth, setDaysInPrevMonth] = useState([])
	const [lastMonthOfPreviousYear, setLastMonthOfPreviousYear] = useState({})

	useEffect(() => {
		const monthsArray = []

		for (let month = 0; month <= currentMonth; month++) {
			const startDate = moment()
				.year(currentYear)
				.month(month)
				.startOf('month')
				.format('DD-MM-YYYY')

			const endDate = moment()
				.year(currentYear)
				.month(month)
				.endOf('month')
				.format('DD-MM-YYYY')

			const monthName = moment().year(currentYear).month(month).format('MMMM')

			monthsArray.push({
				id: month,
				start_date: startDate,
				end_date: endDate,
				name: capitalize(`${monthName} ${currentYear}`),
			})
		}

		const lastMonthStartDate = moment()
			.year(currentYear - 1)
			.month(11)
			.startOf('month')
			.format('DD-MM-YYYY')

		const lastMonthEndDate = moment()
			.year(currentYear - 1)
			.month(11)
			.endOf('month')
			.format('DD-MM-YYYY')

		const lastMonthName = moment()
			.year(currentYear - 1)
			.month(11)
			.format('MMMM')

		setLastMonthOfPreviousYear({
			id: 11,
			start_date: lastMonthStartDate,
			end_date: lastMonthEndDate,
			name: capitalize(`${lastMonthName} ${currentYear - 1}`),
		})

		const initialMonthIndex = currentMonth

		setMonths(monthsArray)
		setThisMonth(monthsArray[initialMonthIndex])
		setDaysInThisMonth(getDaysInMonth(currentYear, currentMonth))
		setDaysInPrevMonth(
			getDaysInMonth(
				currentYear,
				initialMonthIndex === 0 ? 11 : initialMonthIndex - 1
			)
		)
	}, [currentYear, currentMonth])

	useEffect(() => {
		if (!thisMonth) return

		if (thisMonth.id === 0) {
			setPrevMonth(lastMonthOfPreviousYear)
		} else {
			setPrevMonth(months[thisMonth.id - 1])
		}
	}, [thisMonth, months, lastMonthOfPreviousYear])

	return {
		months,
		thisMonth,
		setThisMonth,
		prevMonth,
		daysInThisMonth,
		daysInPrevMonth,
		lastMonthOfPreviousYear,
	}
}
