import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	viewScenario,
	clearViewScenario,
} from '@/redux/slices/scenarioSlices/viewScenarioSlice'

import moment from 'moment/min/moment-with-locales'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { Spinner } from '@/components//ui/general/Spinner'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

const ScenarioField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const ScenarioViewLayout = () => {
	const { demo } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)
	const { scenario, serverStatus } = useSelector(state => state.viewScenario)

	const { t } = useTranslation()
	const id = useParams().id
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const scenarioRef = useRef()

	const handleClickEdit = async id => {
		navigate(`/admin-panel/scenarios/edit/${id}`)
	}

	useEffect(() => {
		dispatch(viewScenario({ id }))

		return () => {
			dispatch(clearViewScenario())
		}
	}, [dispatch])

	useEffect(() => {
		if (scenarioRef.current && isMobile) {
			scenarioRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={scenarioRef} className={styles.scenarioWrapper}>
			<BorderArticle>
				<div className={styles.scenario}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.scenarioContent}>
							<div className={styles.scenarioContentHeadWrapper}>
								<div className={styles.scenarioContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<h2>{scenario?.name}</h2>

										{!demo && (
											<ControlButton
												color='var(--blue)'
												txt={t('button.edit')}
												onClick={() => handleClickEdit(id)}
											/>
										)}
									</div>

									<RootDesc>
										<b>{t('page.scenario.field.preview')}</b>
									</RootDesc>

									<PreviewCard
										name={scenario?.name}
										mask={scenario?.mask}
										image={scenario?.cover}
									/>
								</div>
							</div>

							<ul className={styles.scenarioContentBody}>
								<ScenarioField
									label={t('page.scenario.field.products')}
									value={scenario?.products}
								/>

								<ScenarioField
									label={t('page.scenario.field.purpose')}
									value={scenario?.purpose}
								/>

								<ScenarioField
									label={t('page.scenario.field.objectives')}
									value={scenario?.objectives}
								/>

								<ScenarioField
									label={t('page.scenario.field.focus')}
									value={scenario?.focus}
								/>

								<ScenarioField
									label={t('page.scenario.field.messages')}
									value={scenario?.key_messages}
								/>

								<ScenarioField
									label={t('page.scenario.field.structure')}
									value={scenario?.structure}
								/>

								<ScenarioField
									label={t('page.scenario.field.script')}
									value={scenario?.script}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.scenario.field.documents')}
										</b>
									</RootDesc>

									{scenario?.documents && (
										<SmallDesc>
											<span className={styles.plateDocument}>
												{scenario?.documents}
											</span>
										</SmallDesc>
									)}
								</li>

								<ScenarioField
									label={
										<Trans i18nKey='page.scenario.field.questions'>
											{t('page.scenario.field.questions')}
										</Trans>
									}
									value={scenario?.questions}
								/>

								<ScenarioField
									label={t('page.scenario.field.date')}
									value={
										moment(scenario?.due_date).format('DD-MM-YYYY') ||
										new Date()
									}
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.scenario.field.mandatory')}
										</b>
									</RootDesc>

									<RootCheckbox
										checked={scenario?.mandatory}
										disabled={true}
										onChange={() => console.log('')}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
