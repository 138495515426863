import React from 'react'
import { useSelector } from 'react-redux'

import { Line } from 'react-chartjs-2'
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Tooltip,
} from 'chart.js'

import { useMonths } from '@/hooks/Months'
import RootSelect from '@/components/ui/inputs/RootSelect'
import { H3 } from '@/components/ui/titles/H3'

import styles from './Calls.module.scss'
import { useTranslation } from 'react-i18next'

ChartJS.register(
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Legend,
	Tooltip
)

export const Calls = () => {
	const {
		months,
		thisMonth,
		setThisMonth,
		prevMonth,
		daysInThisMonth,
		daysInPrevMonth,
	} = useMonths()
	const { t } = useTranslation()

	const { width, isMobile } = useSelector(state => state.settings)

	const handleSelect = option => {
		setThisMonth(option)
	}

	const generateRandomData = length => {
		return Array.from({ length }, () => Math.floor(Math.random() * 50))
	}

	let chartLabelPadding = (width * 2) / 100
	let chartFontSize = (width * 0.75) / 100
	let chartPadding = (width * 0.5) / 100
	let chartBorder = (width * 0.25) / 100

	const data = {
		labels: daysInThisMonth,
		datasets: [
			{
				label: thisMonth?.name,
				data: generateRandomData(daysInThisMonth.length),
				fill: true,
				borderColor: '#563797',
				pointBackgroundColor: '#563797',
				tension: 0.6,
			},
			{
				label: prevMonth?.name,
				data: generateRandomData(daysInPrevMonth.length),
				fill: false,
				borderColor: '#F3F3F3',
				pointBackgroundColor: '#F3F3F3',
				tension: 0.6,
			},
		],
	}

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: {
			duration: 1500,
		},
		elements: {
			line: {
				borderWidth: width >= 1920 || isMobile ? 5 : chartBorder,
				capBezierPoints: false,
			},
			point: {
				radius: width >= 1920 || isMobile ? 5 : chartBorder,
				hoverRadius: width >= 1920 || isMobile ? 6 : chartBorder + 0.5,
				borderWidth: 0,
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					boxWidth: width >= 1920 || isMobile ? 20 : chartFontSize,
					boxHeight: width >= 1920 || isMobile ? 20 : chartFontSize,
					padding: width >= 1920 || isMobile ? 20 : chartLabelPadding,
					usePointStyle: true,
					color: '#414040',
					font: {
						size: width >= 1920 || isMobile ? 14 : chartFontSize,
						family: "'IBM Plex Sans', sans-serif",
					},
				},
			},
			tooltip: {
				backgroundColor: 'white',
				titleColor: '#414040',
				bodyColor: '#414040',
				usePointStyles: true,
				borderColor: 'none',
				padding: width >= 1920 || isMobile ? 20 : chartPadding,
				caretPadding: width >= 1920 || isMobile ? 20 : chartPadding,
				cornerRadius: width >= 1920 || isMobile ? 20 : chartPadding,
				boxPadding: width >= 1920 || isMobile ? 20 : chartPadding,
				usePointStyle: true,
				borderColor: 'rgba(247, 207, 221, 0.80)',
				borderWidth: width >= 1920 || isMobile ? 2 : chartBorder - 1.5,
				titleAlign: 'center',
				bodyAlign: 'right',
				titleFont: {
					size: width >= 1920 || isMobile ? 16 : chartFontSize,
					family: "'IBM Plex Sans', sans-serif",
				},
				bodyFont: {
					size: width >= 1920 || isMobile ? 14 : chartFontSize,
					family: "'IBM Plex Sans', sans-serif",
				},
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
				ticks: {
					color: '#414040',
					font: {
						size: width >= 1920 || isMobile ? 14 : chartFontSize,
						family: "'IBM Plex Sans', sans-serif",
					},
				},
			},
			y: {
				display: false,
			},
		},
	}

	return (
		<div className={styles.calls}>
			<div className={styles.callsHeader}>
				<H3>{t('page.dashboard.call_title')}</H3>

				<RootSelect
					options={months}
					onSelect={handleSelect}
					placeholder={thisMonth}
				/>
			</div>

			<div className={styles.callsChart}>
				<Line data={data} options={options} />
			</div>
		</div>
	)
}
