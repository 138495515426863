import React from 'react'
import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const SuccessfulSavePopup = ({ title }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.successfulSave}>
			<h2>
				<Trans title={title} i18nKey='popup.save_data.subtitle'>
					{t('popup.save_data.subtitle')}
					{{ title }}
				</Trans>
			</h2>
		</div>
	)
}
