import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

import { useTable, useSortBy, useGlobalFilter, useRowSelect } from 'react-table'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import TableSelect from '@/components/ui/inputs/TableSelect'
import { Spinner } from '@/components//ui/general/Spinner'
import { WarningData } from '@/components/ui/general/WarningData'
import { TableSort } from '@/components/ui/inputs/TableSort'

import sortImage from '@/assets/images/table/table-sort.svg'
import styles from './styles.module.scss'

export const GlobalFilter = memo(({ filter, setFilter }) => {
	const { t } = useTranslation()

	const handleChange = e => {
		const value = e.target.value || undefined
		if (value !== filter) {
			setFilter(value)
		}
	}

	return (
		<input
			className={styles.searchInput}
			value={filter || ''}
			onChange={handleChange}
			placeholder={t('table.search')}
		/>
	)
})

export const TableLayout = props => {
	const { t } = useTranslation()
	const { role } = useSelector(state => state.candidate)
	const { isMobile } = useSelector(state => state.settings)

	const {
		serverStatus,
		columns,
		data,
		filterCheckbox,
		selectSize,
		toPage,
		dataSize,
		dataSort,
		selectSort,
		totalPages,
	} = props

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		state,
		setGlobalFilter,
		selectedFlatRows,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		useRowSelect
	)

	const sortList = columns
		? columns
				.filter(column => column.accessor)
				.map(column => ({
					name: column.Header,
					value: column.accessor,
				}))
		: []

	useEffect(() => {
		const selectedIds = selectedFlatRows.map(row => row.original.id)

		if (selectedIds.length === 1) {
			console.log('Выбрана строка с id:', selectedIds[0])
		} else if (selectedIds.length > 1) {
			console.log('Выбраны строки с id:', selectedIds)
		}
	}, [selectedFlatRows])

	useEffect(() => {
		console.log('Текущий глобальный фильтр:', state.globalFilter)
	}, [state.globalFilter])

	return (
		<div className={styles.tableWrapper}>
			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : data?.length === 0 ? (
				<WarningData type={'empty'} />
			) : !data && serverStatus === 'error' ? (
				<WarningData type={'error'} />
			) : (
				data?.length > 0 && (
					<>
						<div className={styles.tableSettings}>
							{role !== 'Representative' && (
								<GlobalFilter
									filter={state.globalFilter}
									setFilter={setGlobalFilter}
								/>
							)}

							{isMobile && (
								<TableSort
									options={sortList}
									onSelect={selectSort}
									activeOption={dataSort}
								/>
							)}

							{filterCheckbox && (
								<div className={styles.filterCheckbox}>{filterCheckbox}</div>
							)}

							{!isMobile && (
								<TableSelect
									options={[5, 10, 25, 50, 100]}
									onSelect={selectSize}
									activeOption={dataSize}
								/>
							)}
						</div>

						<table className={styles.table} {...getTableProps()}>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
										{headerGroup.headers.map(column => (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
												key={column.id}
												style={{ width: `${column.width}rem` }}
												title={''}
											>
												<RootDesc>
													<b>
														{column.render('Header')}
														<img src={sortImage} alt='sort' />
													</b>
												</RootDesc>
											</th>
										))}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{rows.map(row => {
									prepareRow(row)

									return (
										<tr {...row.getRowProps()} key={row.id}>
											{row.cells.map(cell => (
												<td
													data-label={typeof cell.column.Header === 'string' ? cell.column.Header : ''}
													{...cell.getCellProps()}
													key={cell.column.id}
												>
													<RootDesc>
														<span>{cell.render('Cell')}</span>
													</RootDesc>
												</td>
											))}
										</tr>
									)
								})}
							</tbody>
						</table>
					</>
				)
			)}

			{data?.length !== 0 && (
				<ReactPaginate
					containerClassName={styles.tableControls}
					pageLinkClassName={styles.pageLink}
					activeClassName={styles.activePage}
					previousClassName={styles.previousPage}
					nextClassName={styles.nextPage}
					disabledClassName={styles.disabledButton}
					renderOnZeroPageCount={null}
					pageRangeDisplayed={isMobile ? 1 : 3}
					marginPagesDisplayed={isMobile ? 1 : 3}
					pageCount={totalPages}
					onPageChange={item => toPage(item?.selected)}
					breakLabel='...'
					nextLabel={
						<button>
							{!isMobile && (
								<RootDesc>
									<span>{t('table.next_page')}</span>
								</RootDesc>
							)}

							<i
								style={{
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderLeft: '9rem solid var(--blueLight)',
								}}
							></i>
						</button>
					}
					previousLabel={
						<button>
							<i
								style={{
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderRight: '9rem solid var(--blueLight)',
								}}
							></i>

							{!isMobile && (
								<RootDesc>
									<span>{t('table.prev_page')}</span>
								</RootDesc>
							)}
						</button>
					}
				/>
			)}
		</div>
	)
}

export default TableLayout
