import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom'
import { store } from '@/redux/store'
import '@/i18n'

import { PopupProvider } from '@/components/layouts/InfoPopup/PopupContext'
import { InfoPopup } from '@/components/layouts/InfoPopup'
import App from '@/components/App/App'

const rootElem = document.getElementById('root')

if (rootElem) {
	const root = ReactDOM.createRoot(rootElem)

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route
				path='*'
				element={
					<>
						<App />
						<InfoPopup width={794} close={true} />
					</>
				}
			/>
		)
	)

	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<PopupProvider>
					<RouterProvider router={router} />
				</PopupProvider>
			</Provider>
		</React.StrictMode>
	)
}
