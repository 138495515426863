import React, { useEffect, useState } from 'react'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import styles from './styles.module.scss'

export const Timer = ({ time }) => {
	const [formatTime, setFormatTime] = useState('')

	useEffect(() => {
		const seconds = Math.floor((time / 1000) % 60)
		const minutes = Math.floor((time / 1000 / 60) % 60)

		setFormatTime(
			`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
		)
	}, [time])

	return (
		<div className={styles.timerWrapper}>
			<RootIcon id='clock' />
			<div
				className={styles.timer}
				style={{
					color: `${
						time <= 60000 && time !== 0
							? 'var(--red)'
							: 'rgba(138, 138, 138, 1)'
					}`,
				}}
			>
				{formatTime}
			</div>
		</div>
	)
}
