import { Route, Routes, useBlocker } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Header } from '@/components/layouts/Header'
import { Footer } from '@/components/layouts/Footer'
import { SideBar } from '@/components/layouts/SideBar'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { UnsavedPopup } from '@/popups/attentionPopups/UnsavedPopup'
import { UserCreateLayout } from '@/components/layouts/UserLayout/UserCreateLayout'
import { Dashboard } from '@/screens/admin/Dashboard'
import { Users } from '@/screens/admin/Users'
import { Products } from '@/screens/admin/Products'
import { Scenarios } from '@/screens/admin/Scenarios'
import { Library } from '@/screens/admin/Library'
import { AdminAnalytics } from '@/screens/admin/AdminAnalytics'
import { ProfileStatisticLayout } from '@/components/layouts/ProfileStatisticLayout'
import { ProductViewLayout } from '@/components/layouts/ProductLayout/ProductViewLayout'
import { ProductEditLayout } from '@/components/layouts/ProductLayout/ProductEditLayout'
import { ProductCreateLayout } from '@/components/layouts/ProductLayout/ProductCreateLayout'
import { ScenarioViewLayout } from '@/components/layouts/ScenarioLayout/ScenarioViewLayout'
import { ScenarioEditLayout } from '@/components/layouts/ScenarioLayout/ScenarioEditLayout'
import { ScenarioCreateLayout } from '@/components/layouts/ScenarioLayout/ScenarioCreateLayout'
import { VideoViewLayout } from '@/components/layouts/VideoLayout/VideoViewLayout'
import { VideoEditLayout } from '@/components/layouts/VideoLayout/VideoEditLayout'
import { VideoCreateLayout } from '@/components/layouts/VideoLayout/VideoCreateLayout'
import { SessionViewLayout } from '@/components/layouts/SessionLayout/SessionViewLayout'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const routes = [
	{ path: 'dashboard', element: <Dashboard /> },
	{ path: 'profile/:id', element: <ProfileStatisticLayout /> },
	{ path: 'users/*', element: <Users /> },
	{ path: 'users/view/:id', element: <ProfileStatisticLayout /> },
	{ path: 'users/edit/:id', element: <ProfileStatisticLayout /> },
	{ path: 'users/create', element: <UserCreateLayout /> },
	{ path: 'products/*', element: <Products /> },
	{ path: 'products/view/:id', element: <ProductViewLayout /> },
	{ path: 'products/edit/:id', element: <ProductEditLayout /> },
	{ path: 'products/create', element: <ProductCreateLayout /> },
	{ path: 'scenarios/*', element: <Scenarios /> },
	{ path: 'scenarios/view/:id', element: <ScenarioViewLayout /> },
	{ path: 'scenarios/edit/:id', element: <ScenarioEditLayout /> },
	{ path: 'scenarios/create', element: <ScenarioCreateLayout /> },
	{ path: 'videos/*', element: <Library /> },
	{ path: 'videos/view/:id', element: <VideoViewLayout /> },
	{ path: 'videos/edit/:id', element: <VideoEditLayout /> },
	{ path: 'videos/create', element: <VideoCreateLayout /> },
	{ path: 'analytics/*', element: <AdminAnalytics /> },
	{ path: 'analytics/view/:id', element: <SessionViewLayout /> },
]

export const AdminPanel = () => {
	const { t } = useTranslation()
	const { openPopup, closePopup } = usePopup()

	const {
		createProduct: { isProductCreate, saveProductCreate },
		editProduct: { isProductEdit, saveProductEdit },
		createScenario: { isScenarioCreate, saveScenarioCreate },
		editScenario: { isScenarioEdit, saveScenarioEdit },
		editUser: { isUserEdit, saveUserEdit },
		createUser: { isUserCreate, saveUserCreate },
		editVideo: { isVideoEdit, saveVideoEdit },
		createVideo: { isVideoCreate, saveVideoCreate },
	} = useSelector(state => ({
		candidate: state.candidate,
		createProduct: state.createProduct,
		editProduct: state.editProduct,
		createScenario: state.createScenario,
		editScenario: state.editScenario,
		editUser: state.editUser,
		createUser: state.createUser,
		editVideo: state.editVideo,
		createVideo: state.createVideo,
	}))

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		const isUnsaved =
			(isProductCreate && !saveProductCreate) ||
			(isProductEdit && !saveProductEdit) ||
			(isScenarioEdit && !saveScenarioEdit) ||
			(isScenarioCreate && !saveScenarioCreate) ||
			(isUserEdit && !saveUserEdit) ||
			(isUserCreate && !saveUserCreate) ||
			(isVideoEdit && !saveVideoEdit) ||
			(isVideoCreate && !saveVideoCreate)

		return isUnsaved && currentLocation.pathname !== nextLocation.pathname
	})

	const adminBarList = [
		{
			id: 0,
			iconId: 'dashboard',
			name: t('sidebar.dashboard'),
			link: 'dashboard',
		},
		{ id: 1, iconId: 'users', name: t('sidebar.users'), link: 'users' },
		{
			id: 2,
			iconId: 'scenarios',
			name: t('sidebar.scenario'),
			link: 'scenarios',
		},
		{ id: 3, iconId: 'products', name: t('sidebar.product'), link: 'products' },
		{ id: 4, iconId: 'library', name: t('sidebar.library'), link: 'videos' },
		{
			id: 5,
			iconId: 'analytics',
			name: t('sidebar.history'),
			link: 'analytics',
		},
	]

	useEffect(() => {
		if (blocker.state === 'blocked') {
			openPopup(
				<UnsavedPopup
					onClickYes={() => {
						blocker.proceed()
						closePopup()
					}}
					onClickNo={() => {
						blocker.reset()
						closePopup()
					}}
				/>,
				{ closeButton: false }
			)
		} else {
			closePopup()
		}
	}, [blocker])

	return (
		<div className={styles.adminPanel}>
			<Header />

			<div className='container-big'>
				<div className={styles.main}>
					<SideBar list={adminBarList} />

					<Routes>
						{routes.map((route, index) => (
							<Route key={index} path={route.path} element={route.element} />
						))}
					</Routes>
				</div>
			</div>

			<Footer />
		</div>
	)
}
