import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StreamingAvatar, {
	AvatarQuality,
	StreamingEvents,
} from '@heygen/streaming-avatar'
import {
	clearAvatarState,
	createScenarioSession,
	setIsSessionActive,
	getQuestion,
	createProductSession,
	setScore,
	setProductSessionId,
	setScenarioSessionId,
} from '@/redux/slices/avatarSlices/avatarSlice'
import { useTranslation } from 'react-i18next'

import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '@/components/ui/general/Spinner'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { AvatarErrorPopup } from '@/popups/simulationPopups/AvatarErrorPopup'

import styles from './styles.module.scss'

const AvatarComponent = ({ time, setTime, restartTime }) => {
	const {
		scenario_session_id,
		product_session_id,
		avatarToken,
		avatarId,
		isSessionActive,
		botMessage,
		score,
		sessionState,
		questions,
		messages,
		volume,
	} = useSelector(state => state.avatar)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const level = location.state?.level
	const { openPopup, closePopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)

	const videoRef = useRef(null)
	const [avatar, setAvatar] = useState(null)
	const [sessionData, setSessionData] = useState(null)
	const [avatarLoading, setAvatarLoading] = useState(false)

	const initializeAvatarSession = async () => {
		if (avatarToken) {
			setAvatarLoading(true)

			try {
				const newAvatar = new StreamingAvatar({ token: avatarToken })
				const data = await newAvatar.createStartAvatar({
					quality: AvatarQuality.High,
					avatarName: avatarId,
					// language: 'Russian',
					voice: {
						rate: 1.5,
						emotion: 'serious',
					},
				})

				setSessionData(data)
				setAvatar(newAvatar)
				dispatch(setIsSessionActive(true))

				if (product_session_id) {
					dispatch(getQuestion({ id: product_session_id }))
				}

				newAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady)
				newAvatar.on(
					StreamingEvents.STREAM_DISCONNECTED,
					handleStreamDisconnected
				)
			} catch {
				openPopup(
					<AvatarErrorPopup
						onClickTryAgain={handleTryAgain}
						onClickExit={handleQuit}
					/>,
					{ closeButton: false }
				)
			}

			setAvatarLoading(false)
		}
	}

	const handleStreamReady = event => {
		if (event.detail && videoRef.current) {
			videoRef.current.srcObject = event.detail
			videoRef.current.onloadedmetadata = () => {
				videoRef.current.play().catch(console.error)
			}
		} else {
			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)
		}
	}

	const handleStreamDisconnected = () => {
		if (videoRef.current) {
			videoRef.current.srcObject = null
		}
		dispatch(setIsSessionActive(false))
	}

	const terminateAvatarSession = async () => {
		if (!avatar || !sessionData) return

		await avatar.stopAvatar()

		if (videoRef.current) {
			videoRef.current.srcObject = null
		}

		setAvatar(null)
		setSessionData(null)
		dispatch(setIsSessionActive(false))
	}

	const handleSpeak = async msg => {
		await avatar.speak({ text: msg, task_type: 'repeat' })
	}

	const handleClickQuitBtn = async () => {
		if (messages.length > 1) {
			await terminateAvatarSession()
			restartTime(Date.now(), false)
		} else {
			await terminateAvatarSession()
			navigate(-1)
			dispatch(setProductSessionId(null))
			dispatch(setScenarioSessionId(null))
		}
	}

	const handleTryAgain = async () => {
		await dispatch(clearAvatarState())
		await dispatch(setScore(null))

		if (product_session_id) {
			await dispatch(
				createProductSession({
					level: params.level,
					product_id: params.scenario_id,
				})
			)

			await dispatch(getQuestion({ id: product_session_id }))

			restartTime(new Date(Date.now() + level.remainingProductTime), false)
		} else {
			await dispatch(
				createScenarioSession({
					level: params.level,
					scenario_id: params.scenario_id,
				})
			)

			restartTime(new Date(Date.now() + level.remainingScenarioTime), false)
		}

		closePopup()
	}

	const handleQuit = async () => {
		await dispatch(setScore(null))
		await terminateAvatarSession()
		await dispatch(clearAvatarState())
		await dispatch(setProductSessionId(null))
		await dispatch(setScenarioSessionId(null))
		navigate(-1)
		closePopup()
	}

	const handleCloseLevelPopup = async () => {
		closePopup()
		initializeAvatarSession()
	}

	useEffect(() => {
		if (
			(scenario_session_id && score === 0) ||
			(scenario_session_id && sessionState === 'ended') ||
			time === 0 ||
			questions === 0
		) {
			setTime(0)
			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		}
	}, [time, questions, score, sessionState, scenario_session_id])

	useEffect(() => {
		if (avatar && botMessage !== '') {
			handleSpeak(botMessage)
		}
	}, [botMessage])

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.volume = volume
		}
	}, [volume])

	useEffect(() => {
		openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
			closeButton: false,
		})

		return () => {
			if (avatarToken === '') {
				closePopup()
				dispatch(clearAvatarState())
				navigate('/simulations')
			}
		}
	}, [])

	useEffect(() => {
		if (avatar && sessionData && avatarToken !== '') {
			initializeAvatarSession()
		}
	}, [avatarToken])

	return (
		<>
			<div className={styles.avatarWrapper}>
				<div className={styles.avatarPlayer}>
					{!isSessionActive || avatarLoading ? (
						<Spinner width={80} height={80} />
					) : (
						<video
							style={{ pointerEvents: 'none' }}
							id='avatarVideo'
							ref={videoRef}
							autoPlay
							playsInline
						/>
					)}
				</div>
			</div>

			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>

				{!isMobile && (
					<RootDesc>
						<span>{t('button.exit')}</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default AvatarComponent
