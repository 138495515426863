import React from 'react'

import styles from './styles.module.scss'

export const MaskList = ({ onClickCover }) => {
	const coverList = [
		{ id: 0, name: 'peachGr' },
		{ id: 1, name: 'violetGr' },
		{ id: 2, name: 'pinkGr' },
	]

	return (
		<ul className={styles.maskList}>
			{coverList.map(item => (
				<li
					onClick={() => onClickCover(item.name)}
					style={{ background: `var(--${item.name})` }}
					key={item.id}
				></li>
			))}
		</ul>
	)
}
