import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment/min/moment-with-locales'

import Calendar from 'react-calendar'
import { RootDesc } from '../../descriptions/RootDesc'
import { BorderArticle } from '../BorderArticle'

import '@/styles/components/calendar.scss'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

export const DropCalendar = ({ date, setDate }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { language } = useSelector(state => state.settings)

	const calendarRef = useRef(null)

	const toggleSelect = () => {
		setIsOpen(!isOpen)
	}

	const handleChangeDate = currentDate => {
		setDate(currentDate)
		setIsOpen(false)
	}

	const handleClickOutside = event => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div ref={calendarRef} className={styles.calendarWrapper}>
			<div onClick={toggleSelect} className={styles.calendarHead}>
				<RootDesc>
					<span>{moment(date).format('DD-MM-YYYY')}</span>
				</RootDesc>

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>
			</div>

			{isOpen && (
				<div className={styles.calendar}>
					<BorderArticle>
						<Calendar
							minDate={new Date()}
							locale={language}
							onChange={currentDate => handleChangeDate(currentDate)}
							value={date}
						/>
					</BorderArticle>
				</div>
			)}
		</div>
	)
}
