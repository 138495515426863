import React from 'react'
import styles from './styles.module.scss'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { DropZone } from '@/components/ui/general/DropZone'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { useTranslation } from 'react-i18next'

export const UploadImagePopup = ({ setPhotoFile }) => {
	const { closePopup } = usePopup()
	const { t } = useTranslation()

	const uploadFile = file => {
		if (file) {
			setPhotoFile(file)
			closePopup()
		} else {
			console.log('File is undefined!')
		}
	}

	return (
		<div className={styles.upload}>
			<H3>{t('popup.upload_image.title')}</H3>

			<div className={styles.uploadDesc}>
				<RootDesc>
					<span>{t('popup.upload_image.subtitle')}</span>
				</RootDesc>
			</div>

			<DropZone
				maxFiles={1}
				accept={{ 'image/*': ['.png', '.jpg'] }}
				onUpload={uploadFile}
				size={50000000}
				type={'jpg, png'}
			/>
		</div>
	)
}
