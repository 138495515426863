import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AvatarService from '@/services/AvatarService'
import { v4 as uuidv4 } from 'uuid'

export const createScenarioSession = createAsyncThunk(
	'create/scenario_session',
	async ({ level, scenario_id }) => {
		try {
			const response = await AvatarService.createScenarioSession({
				level,
				scenario_id,
			})

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const createProductSession = createAsyncThunk(
	'create/product_session',
	async ({ level, product_id }) => {
		try {
			const response = await AvatarService.createProductSession({
				level,
				product_id,
			})

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const sendMessage = createAsyncThunk(
	'send/message',
	async ({ id, msg }) => {
		try {
			const response = await AvatarService.sendMessage({ id, msg })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getQuestion = createAsyncThunk('get/question', async ({ id }) => {
	try {
		const response = await AvatarService.getQuestion({ id })

		return response.data
	} catch (err) {
		throw err
	}
})

export const sendAnswer = createAsyncThunk(
	'send/answer',
	async ({ id, answer_id, answer }) => {
		try {
			const response = await AvatarService.sendAnswer({ id, answer_id, answer })

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getScenarioFeedback = createAsyncThunk(
	'session/scenario-feedback',
	async id => {
		try {
			const response = await AvatarService.getScenarioFeedback(id)

			return response.data
		} catch (err) {
			throw err
		}
	}
)

export const getProductFeedback = createAsyncThunk(
	'session/product-feedback',
	async id => {
		try {
			const response = await AvatarService.getProductFeedback(id)

			return response.data
		} catch (err) {
			throw err
		}
	}
)

const initialState = {
	avatarId: 'afffcc32c461440a94eff11c61eb0eb0',
	avatarToken: '',
	userMessage: '',
	botMessage: '',
	messages: [],
	score: null,
	scenario_session_id: null,
	product_session_id: null,
	answer_id: null,
	isRecording: false,
	isSessionActive: false,
	sessionState: '',
	questions: null,
	volume: 0.2,
	error: '',
	serverStatus: '',
	feedback: '',
}

const avatarSlice = createSlice({
	name: 'avatar',
	initialState,
	reducers: {
		setScore(state, action) {
			state.score = action.payload
		},
		setScenarioSessionId(state, action) {
			state.scenario_session_id = action.payload
		},
		setProductSessionId(state, action) {
			state.product_session_id = action.payload
		},
		setIsRecording(state, action) {
			state.isRecording = action.payload
		},
		setIsSessionActive(state, action) {
			state.isSessionActive = action.payload
		},
		setAnswerId(state, action) {
			state.answer_id = action.payload
		},
		setQuestions(state, action) {
			state.questions = action.payload
		},
		setUserMessage(state, action) {
			state.userMessage = action.payload
		},
		setBotMessage(state, action) {
			state.botMessage = action.payload
		},
		setMessages(state, action) {
			state.messages.push(action.payload)
		},
		setVolume(state, action) {
			state.volume = action.payload
		},
		setAvatarToken(state, action) {
			state.avatarToken = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearAvatarState(state, action) {
			state.userMessage = ''
			state.botMessage = ''
			state.answer_id = null
			state.messages = []
			state.sessionState = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createScenarioSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(createScenarioSession.fulfilled, (state, action) => {
				state.scenario_session_id = action?.payload?.id
				state.avatarToken = action?.payload?.heygen?.data?.token || ''
				state.error = action?.payload?.heygen?.error || ''
				state.serverStatus = 'success'
			})
			.addCase(createScenarioSession.rejected, state => {
				state.error = 'Don`t create session!'
				state.serverStatus = 'error'
			})

			.addCase(createProductSession.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(createProductSession.fulfilled, (state, action) => {
				state.product_session_id = action?.payload?.id
				state.avatarToken = action?.payload?.heygen?.data?.token || ''
				state.questions = action?.payload?.questions_count - 1
				state.error = action?.payload?.heygen?.error || ''
				state.serverStatus = 'success'
			})
			.addCase(createProductSession.rejected, state => {
				state.error = 'Don`t create session!'
				state.serverStatus = 'error'
			})

			.addCase(getScenarioFeedback.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getScenarioFeedback.fulfilled, (state, action) => {
				state.feedback = action.payload.feedback
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getScenarioFeedback.rejected, state => {
				state.error = 'Don`t get feedback!'
				state.serverStatus = 'error'
			})

			.addCase(getProductFeedback.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getProductFeedback.fulfilled, (state, action) => {
				state.feedback = action.payload.feedback
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getProductFeedback.rejected, state => {
				state.error = 'Don`t get feedback!'
				state.serverStatus = 'error'
			})

			.addCase(sendMessage.pending, state => {
				state.serverStatus = 'loading'
				state.sessionState = ''
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.botMessage = action.payload.ai
				state.score = action.payload.score
				state.sessionState = action.payload.state
				state.error = ''
				state.serverStatus = 'success'

				if (action.payload) {
					const bot = {
						messageId: uuidv4(),
						text: state.botMessage,
						sender: 'bot',
					}

					state.messages.push(bot)
				}
			})
			.addCase(sendMessage.rejected, state => {
				state.error = 'Don`t send message!'
				state.sessionState = ''
				state.serverStatus = 'error'
			})

			.addCase(getQuestion.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getQuestion.fulfilled, (state, action) => {
				state.botMessage = action.payload.text
				state.answer_id = action.payload.answer_id
				state.error = ''
				state.serverStatus = 'success'

				if (action.payload) {
					const bot = {
						messageId: uuidv4(),
						text: state.botMessage,
						sender: 'bot',
					}

					state.messages.push(bot)
				}
			})
			.addCase(getQuestion.rejected, state => {
				state.error = 'Don`t get question!'
				state.serverStatus = 'error'
			})

			.addCase(sendAnswer.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(sendAnswer.fulfilled, (state, action) => {
				state.score = action.payload.score
				state.questions = state.questions > 0 ? state.questions - 1 : null
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(sendAnswer.rejected, state => {
				state.error = 'Don`t send answer!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setScore,
	setScenarioSessionId,
	setProductSessionId,
	setQuestions,
	setBotMessage,
	setUserMessage,
	setMessages,
	setIsSessionActive,
	setVolume,
	setAvatarToken,
	setError,
	setServerStatus,
	serverStatus,
	clearAvatarState,
	setIsRecording,
	setAnswerId,
} = avatarSlice.actions
export default avatarSlice.reducer
