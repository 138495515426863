import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearScenarioOptions,
	getProducts,
} from '@/redux/slices/scenarioSlices/scenarioOptionsSlice'
import {
	clearProductOptions,
	getCountries,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'
import {
	clearCreateVideo,
	setCreateVideo,
	setIsVideoCreate,
	initialCreateVideo,
} from '@/redux/slices/videoSlices/createVideoSlice'

import { isEqual } from '@/helpers/helpers'
import { BorderArticle } from '@/components//ui/general/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import { usePopup } from '../InfoPopup/PopupContext'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { DocumentPreview } from '@/components/ui/general/DocumentPreview'
import { PreviewCard } from '@/components/ui/general/PreviewCard'
import { Spinner } from '@/components/ui/general/Spinner'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const VideoCreateLayout = () => {
	const { openPopup } = usePopup()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()

	const { isMobile } = useSelector(state => state.settings)
	const { createVideo, serverStatus } = useSelector(state => state.createVideo)
	const { products } = useSelector(state => state.scenarioOptions)
	const { productLine, countries } = useSelector(state => state.productOptions)

	const [videoFile, setVideoFile] = useState(null)
	const [photoFile, setPhotoFile] = useState(null)

	const videoRef = useRef()

	const updateVideoField = (fieldName, value) => {
		dispatch(setCreateVideo({ ...createVideo, [fieldName]: value }))
	}

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
		updateVideoField('cover', null)
	}

	const handleClickRemoveVideo = () => {
		setVideoFile(null)
		updateVideoField('video', null)
	}

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickUploadVideo = () => {
		openPopup(<UploadImagePopup setPhotoFile={setVideoFile} />)
	}

	const handleSelect = selectedOption => {
		const productId = selectedOption.id
		const currentProductIds = createVideo.product_ids || []

		if (currentProductIds.includes(productId)) {
			updateVideoField(
				'product_ids',
				currentProductIds.filter(id => id !== productId)
			)
		} else {
			updateVideoField('product_ids', [...currentProductIds, productId])
		}
	}

	useEffect(() => {
		if (createVideo && initialCreateVideo) {
			dispatch(setIsVideoCreate(!isEqual(createVideo, initialCreateVideo)))
		}
	}, [createVideo, initialCreateVideo, dispatch])

	useEffect(() => {
		dispatch(getProducts())
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearCreateVideo())
			dispatch(clearScenarioOptions())
			dispatch(clearProductOptions())
		}
	}, [dispatch])

	useEffect(() => {
		if (videoRef.current && isMobile) {
			videoRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [location])

	return (
		<div ref={videoRef} className={styles.videoWrapper}>
			<BorderArticle>
				<div className={styles.video}>
					<div className={styles.videoHead}>
						<BackButton />
					</div>

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.videoContent}>
							<div className={styles.videoEditContent}>
								<div className={styles.videoContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<EditInput
											width={!isMobile ? 350 : 210}
											placeholder={t('input.video_name_placeholder')}
											label='video-name'
											type='text'
											value={createVideo?.name}
											onChange={e => updateVideoField('name', e.target.value)}
										/>

										<ControlButton
											color='var(--green)'
											txt={t('button.save')}
											onClick={() => console.log('save')}
										/>
									</div>

									<div className='flex items-start gap-[16rem] phone:flex-col'>
										<RootDesc>
											<b className='block w-[150rem]'>
												{t('page.video.field.video')}
											</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											{videoFile ||
												(createVideo?.video && (
													<DocumentPreview
														name={
															videoFile?.name ||
															createVideo?.video_metadata?.filename
														}
														onClickCross={() => handleClickRemoveVideo()}
													/>
												))}

											<ControlButton
												color='var(--violetLight)'
												txt={t('button.upload_video')}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>

									<div className='flex items-start gap-[16rem] phone:flex-col'>
										<RootDesc>
											<b className='block w-[150rem]'>
												{t('page.scenario.field.image')}
											</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											{photoFile ||
												(createVideo?.cover && (
													<DocumentPreview
														name={
															photoFile?.name ||
															createVideo?.cover_metadata?.filename
														}
														onClickCross={() => handleClickRemovePhoto()}
													/>
												))}

											<ControlButton
												color='var(--violetLight)'
												txt={t('button.upload_image')}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>
								</div>

								<div className='flex flex-col gap-[24rem] items-start'>
									<RootDesc>
										<b>{t('page.scenario.field.preview')}</b>
									</RootDesc>

									<BorderArticle>
										<PreviewCard
											name={createVideo?.name}
											mask={createVideo?.mask}
											image={
												photoFile
													? URL.createObjectURL(photoFile)
													: createVideo?.cover || undefined
											}
										/>
									</BorderArticle>
								</div>
							</div>

							<ul className={styles.videoContentBody + ' ' + styles.videoBody}>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.scenario.field.products')}
											<sup>*</sup>
										</b>
									</RootDesc>

									{products && products.length > 0 && (
										<div className='w-[280rem] phone:w-[100%]'>
											<EditSelect
												width={500}
												searchPlaceholder={t('input.product_search')}
												multiSelect={true}
												activeOption={[]}
												options={products}
												onSelect={selectedOption =>
													handleSelect(selectedOption)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.product.field.product_line')}
											<sup>*</sup>
										</b>
									</RootDesc>

									{productLine && productLine.length > 0 && (
										<div className='w-[280rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder={t('input.product_line_search')}
												activeOption={createVideo?.product_line}
												options={productLine}
												onSelect={selectedOption =>
													updateVideoField(
														'product_line_id',
														selectedOption?.id
													)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											{t('page.product.field.country')}
											<sup>*</sup>
										</b>
									</RootDesc>

									{countries && countries.length > 0 && (
										<div className='w-[280rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder={t('input.country_search')}
												activeOption={createVideo?.country}
												options={countries}
												onSelect={selectedOption =>
													updateVideoField('country_id', selectedOption?.id)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											{t('page.video.field.description')}
										</b>
									</RootDesc>

									<RootTextarea
										type={'text'}
										value={createVideo?.description}
										onChange={e =>
											updateVideoField('description', e.target.value)
										}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
