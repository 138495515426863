import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
	signIn,
	setError,
	setEmail,
	setPassword,
	toggleConsent,
} from '@/redux/slices/candidateSlice'

import { isValidEmail } from '@/helpers/helpers'
import { RootPopup } from '@/components/layouts/RootPopup'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { HelpPopup } from '@/popups/requestPopups/HelpPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { CallPopup } from '@/popups/requestPopups/CallPopup'
import { PresentationPopup } from '@/popups/requestPopups/PresentationPopup'
import { Spinner } from '@/components//ui/general/Spinner'
import { FormError } from '@/components/ui/general/FormError'
import { Logo } from '@/components/ui/general/Logo'

import fallbackImage from '@/assets/images/general/video-fallback.png'
import styles from './styles.module.scss'

// import videoMp4 from '@/assets/video.mp4'
// import videoMov from '@/assets/video.mov'
// import videoWebm from '@/assets/video.webm'

export const Login = () => {
	const videoMov = '/video/video.mov'
	const videoWebm = '/video/video.webm'
	const videoMp4 = '/video/video.mp4'

	const { isMobile, navigateHistory } = useSelector(state => state.settings)
	const { email, password, consent, error, serverStatus } = useSelector(
		state => state.candidate
	)
	const { openPopup } = usePopup()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const videoRef = useRef(null)

	const handleOpenPopup = popup => {
		openPopup(popup)
	}

	const handleSubmit = async e => {
		e.preventDefault()
		dispatch(setError(''))

		if (!isValidEmail(email) || !password) {
			return dispatch(setError(t('server_error.login')))
		} else if (!consent) {
			return dispatch(setError(t('server_error.consent')))
		}

		const resultAction = await dispatch(signIn({ email, password }))

		if (signIn.fulfilled.match(resultAction)) {
			const userRole = resultAction.payload.type

			if (userRole === 'Admin' || userRole === 'Manager') {
				navigate(
					navigateHistory && navigateHistory.includes('admin-panel')
						? navigateHistory
						: '/admin-panel/dashboard'
				)
			} else {
				navigate(
					navigateHistory && navigateHistory.includes('user-panel')
						? navigateHistory
						: '/simulations/call'
				)
			}
		} else {
			dispatch(setError(t('server_error.login')))
		}
	}

	const buttonList = [
		{
			id: 0,
			name: t('page.login.popup_links.call'),
			iconId: 'call',
			popup: <CallPopup />,
		},
		{
			id: 1,
			name: t('page.login.popup_links.presentation'),
			iconId: 'request',
			popup: <PresentationPopup />,
		},
		{
			id: 2,
			name: t('page.login.popup_links.help'),
			iconId: 'question',
			popup: <HelpPopup />,
		},
	]

	const footerLinkList = [
		{
			id: 0,
			name: t('footer.links.privacy'),
			link: '/privacy',
		},
		{
			id: 1,
			name: t('footer.links.terms'),
			link: '/terms',
		},
	]

	useEffect(() => {
		dispatch(setError(''))
	}, [email, password, consent])

	useEffect(() => {
		if (!isMobile) {
			const videoElement = videoRef?.current

			if (videoElement) {
				const handleTimeUpdate = () => {
					if (
						Math.round(videoElement?.currentTime) >=
						Math.round(videoElement?.duration) - 1
					) {
						videoElement.pause()
					}
				}

				videoElement.addEventListener('timeupdate', handleTimeUpdate)
				videoElement.play()

				return () => {
					videoElement.removeEventListener('timeupdate', handleTimeUpdate)
				}
			}
		}
	}, [isMobile])

	return (
		<div className={styles.loginWrapper}>
			<div className='container-big'>
				<div className={styles.login}>
					<Logo />

					<div className={styles.loginContent}>
						<h1>{t('page.login.title')}</h1>

						<h2>
							<Trans i18nKey='page.login.subtitle'>
								{t('page.login.subtitle')}
							</Trans>
						</h2>

						<div className={styles.loginPopup}>
							<RootPopup width={440}>
								<div className={styles.content}>
									<RootDesc>
										<span className='inline-block text-center w-[100%]'>
											<Trans i18nKey='page.login.popup_subtitle'>
												{t('page.login.popup_subtitle')}
											</Trans>
										</span>
									</RootDesc>

									<form onSubmit={handleSubmit} className={styles.form}>
										<div className={styles.inputs}>
											<div className={styles.input}>
												<RootInput
													width={220}
													label={t('input.login')}
													type={'text'}
													value={email}
													require={true}
													onChange={e => dispatch(setEmail(e.target.value))}
													disabled={serverStatus === 'loading'}
												/>
											</div>

											<div className={styles.input}>
												<RootInput
													width={220}
													label={t('input.password')}
													type={'password'}
													value={password}
													require={true}
													onChange={e => dispatch(setPassword(e.target.value))}
													disabled={serverStatus === 'loading'}
												/>
											</div>
										</div>

										<div className={styles.loginCheckbox}>
											<RootCheckbox
												disabled={serverStatus === 'loading'}
												checked={consent}
												onChange={() => dispatch(toggleConsent())}
												label={
													<>
														<Trans i18nKey='input.privacy_checkbox_label'>
															{t('input.privacy_checkbox_label')}
														</Trans>{' '}
														<Link to={'/privacy'}>
															<Trans i18nKey='input.privacy_checkbox_link'>
																{t('input.privacy_checkbox_link')}
															</Trans>
														</Link>
													</>
												}
											/>
										</div>

										<RootButton
											type={'submit'}
											txt={<span>{t('page.login.popup_button')}</span>}
											disabled={
												!email ||
												!password ||
												!consent ||
												serverStatus === 'loading'
											}
										/>

										{error !== '' && <FormError error={error} bottom={105} />}

										{serverStatus === 'loading' && (
											<Spinner width={50} height={50} />
										)}
									</form>
								</div>
							</RootPopup>

							<ul className={styles.loginButtons}>
								{buttonList.map(btn => (
									<li key={btn.id}>
										<ControlButton
											color='var(--black)'
											txt={btn.name}
											iconId={btn.iconId}
											onClick={() => handleOpenPopup(btn.popup)}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{!isMobile && (
				<div className={styles.loginVideoWrapper}>
					<div className='container-big'>
						<div className={styles.loginVideo}>
							{videoMp4 && videoWebm && videoMov && (
								<video
									ref={videoRef}
									autoPlay={true}
									loop={false}
									muted={true}
									width={'1840rem'}
									height={'900rem'}
									poster={fallbackImage}
								>
									<source src={videoMov} type='video/quicktime' />
									<source src={videoWebm} type='video/webm' />
									<source src={videoMp4} type='video/mp4' />
								</video>
							)}
						</div>
					</div>
				</div>
			)}

			<div className={styles.loginFooter}>
				<div className='container-big'>
					<div className={styles.footer}>
						<RootDesc>
							<span className='text-[var(--white)] phone:text-[var(--grey)]'>
								{t('footer.copyright')}
							</span>
						</RootDesc>

						<ul>
							{footerLinkList.map(item => (
								<li key={item.id}>
									<Link to={item.link}>{item.name}</Link>
								</li>
							))}
						</ul>

						<RootDesc>
							<span className='text-[var(--white)] phone:text-[var(--grey)] phone:block phone:text-center'>
								{t('footer.contact')}{' '}
								<a
									href='mailto:info@vivanti.eu'
									target='_blank'
									rel='noopener noreferrer'
								>
									info@vivanti.eu
								</a>
							</span>
						</RootDesc>
					</div>
				</div>
			</div>
		</div>
	)
}
