import React from 'react'

import { H3 } from '@/components/ui/titles/H3'
import { StatisticCardsLayout } from '../StatisticCardsLayout'

import activeImage from '@/assets/images/cards/user.png'
import successImage from '@/assets/images/cards/user-set.png'
import rejectImage from '@/assets/images/cards/user-rem.png'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const Statistic = () => {
	const { t } = useTranslation()

	const statisticList = [
		{
			id: 0,
			icon: activeImage,
			title: t('overview_card.total_title'),
			total: 120,
			totalChange: 15,
		},
		{
			id: 1,
			icon: successImage,
			title: t('overview_card.success_title'),
			total: 88,
			totalChange: 25,
		},
		{
			id: 2,
			icon: rejectImage,
			title: t('overview_card.reject_title'),
			total: 32,
			totalChange: -3,
		},
	]

	return (
		<div className={styles.statistic}>
			<div className={styles.statisticHead}>
				<H3>{t('page.profile.statistic.title')}</H3>
			</div>

			<StatisticCardsLayout list={statisticList} />
		</div>
	)
}
