import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { useLocation } from 'react-router-dom'

export const Overlay = ({ color, children }) => {
	const location = useLocation()

	useEffect(() => {
		const scrollBarWidth =
			window.innerWidth - document.documentElement.clientWidth

		document.body.style.overflow = 'hidden'
		document.body.style.paddingRight = `${scrollBarWidth}rem`

		return () => {
			document.body.style.overflow = ''
			document.body.style.paddingRight = ''
		}
	}, [])

	return (
		<div
			style={
				color
					? { background: `${color}` }
					: { background: `rgba(217, 217, 217, 0.5)` }
			}
			className={`${styles.overlay} ${
				location.pathname.includes('chat-game') && styles.overlayStart
			}`}
		>
			{children}
		</div>
	)
}
