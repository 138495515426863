import React from 'react'
import { useNavigate } from 'react-router-dom'

import { RootIcon } from '../../icons/RootIcon'
import { RootDesc } from '../../descriptions/RootDesc'

import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const BackButton = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<button className={styles.backBtn} onClick={() => navigate(-1)}>
			<RootIcon width={17} height={17} id={'arrow-back'} />

			<RootDesc>
				<span>{t('button.back')}</span>
			</RootDesc>
		</button>
	)
}
