import $api from '../http'

export default class AvatarService {
	static async createScenarioSession({ level, scenario_id }) {
		return $api.post('/api/v1/scenario_sessions', { level, scenario_id })
	}

	static async createProductSession({ level, product_id }) {
		return $api.post('/api/v1/product_sessions', { level, product_id })
	}

	static async getQuestion({ id }) {
		return $api.post(`/api/v1/product_sessions/${id}/question`)
	}

	static async sendAnswer({ id, answer_id, answer }) {
		return $api.post(`/api/v1/product_sessions/${id}/answer`, {
			answer_id,
			answer,
		})
	}

	static async sendMessage({ id, msg }) {
		return $api.post(`/api/v1/scenario_sessions/${id}/message`, { id, msg })
	}

	static async getScenarioFeedback(id) {
		return $api.post(`/api/v1/scenario_sessions/${id}/feedback`)
	}

	static async getProductFeedback(id) {
		return $api.post(`/api/v1/product_sessions/${id}/feedback`)
	}
}
